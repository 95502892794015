.appcontainer {
  /* height: 100vh; */
  width: 100%;
}

.spaceb {
  margin-top: -30px;
}
.space1b {
  margin-top: 10px;
}

.sidenav {
  flex: 0 0 300px;
  /* Fixed width for the sidebar */
  background-color: #331313;
  overflow-y: auto;
  /* Enable vertical scrolling */
  position: fixed;
  /* Fix the sidebar in place */
  height: 100vh;
  /* Full height of the viewport */
}

.maincontent {
  /* margin-left: 300px; */
  /* Same width as the sidebar to prevent overlap */
  /* flex: 1; */
  /* background-color: #f0f0f0; */
  /* overflow-y: auto; */
  /* Ensure main content can scroll if needed */
  /* height: 100vh; */
  /* Full height of the viewport */
}

.scrollable {
  padding: 30px;
  /* Additional styles for the scrollable content inside the sidebar */
}

.pysf {
  width: 296.3px;
  height: 152.78px;
  margin-bottom: 18px;
}

/* img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease
  } */

img:hover {
  /* transform: scale(1.1); */
}

.rgstrurcurses {
  width: 296.3px;
  height: 152.78px;
}

.hostlfees {
  width: 140.74px;
  height: 219.44px;
}

.fundurwlt {
  width: 140.74px;
  height: 219.44px;
}

.chekurrslt {
  width: 140.74px;
  height: 219.44px;
}

.gturcrsmtrls {
  width: 140.74px;
  height: 219.44px;
}

.qlnks {
  display: flex;
  /* justify-content: space-around; */
  align-items: left;
  margin-left: 25px;
  gap: 18px;
}

.dshbrdtxt {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #ced4da;
  padding-left: 25px;
  padding-bottom: 15px;
  margin-top: 10px;
}

.frstcolumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: auto;
  /* overflow: hidden; */
}

.frstsubcolumn {
  display: flex;
  justify-content: space-between;
}

.qlnktxt {
  font-size: 12px;
  font-weight: 400;
  color: #6c757d;
  padding-left: 25px;
  margin-top: -15px;
}

.firstImg:hover {
  transform: none;
}

.firstImg {
  width: 100%;
  height: 250px;
  /* padding: 20px; */
}
.receivableCont {
  width: 100%;
  height: 170px;
  border: 0.5px solid #2d995f;
  border-radius: 8px;
  /* box-shadow: 0px 4px 20px 0px #ACE7C73D; */
  padding: 20px;
}
.receivableCont1 {
  width: 100%;
  height: 170px;
  border: 0.5px solid #2d995f;
  border-radius: 8px;
  overflow-y: auto; /* Enable vertical scrolling */

  position: relative;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.receivableCont1::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0;
}

.adminHeader {
  background-color: rgb(240, 238, 238);
  color: white;
  padding: 10px;
  position: sticky; /* Use sticky positioning */
  top: 0; /* Stick to the top of the container */
  z-index: 10; /* Ensure it stays above the scrolling content */
  border-bottom: 1px solid #ccc; /* Dividing line */
}

.settingsContent {
  /* padding-top: 50px; Add padding to account for the fixed header */
}

.payabbleCont {
  /* width: 100%; */
  height: 170px;
  border: 0.5px solid #2d995f;
  border-radius: 8px;
  /* box-shadow: 0px 4px 20px 0px #ACE7C73D; */
  padding: 30px 0 0 30px;
}
.divd {
  margin-top: 10px;
}
.receivableCont h1 {
  font-size: 14px;
  color: #343a40;
  font-weight: 400;
}
.receivableCont h2 {
  font-size: 10px;
  margin-top: 28px;
  color: #6c757d;
  font-weight: 300;
}
.receivableCont h3 {
  font-size: 28px;
  color: #343a40;
  font-weight: 700;
}
.receivableBelow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.firstCont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.payableCont {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.payableText {
  display: flex;
  flex-direction: column;
}
.payableText h1 {
  font-size: 10px;
  color: #6c757d;
  font-weight: 300;
}
.payableText h3 {
  font-size: 18px;
  color: #343a40;
  font-weight: 700;
  margin-top: -10px;
}
.payableText h4 {
  font-size: 10px;
  color: #2d995f !important;
  font-weight: 300;
}
.payableText h6 {
  font-size: 10px;
  color: #ff1708 !important;
  font-weight: 300;
}
.receivableBelowCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashBoardCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.firstGrid {
  display: flex;
  padding: 10px;
  /* background-color: #ffffff; */
  /* background-image: url('../Assets/firstGridBck.svg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.topTflex {
  margin-top: 35px;
  /* display: flex; */
  text-align: left;
}

.topTflex h3 {
  font-size: 22px;
  font-weight: 700;
  color: #2d995f;
  margin: 3px;
}

.topTflex p {
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  margin: 3px;
}

.topThflex {
  margin-top: 44px;
  text-align: left;
}

.topThflex h6 {
  margin: 3px;
  font-size: 13px;
  font-weight: 400;
  color: #2d995f;
}

.topThflex p {
  margin: 3px;
  font-size: 15px;
  font-weight: 400;
  color: #6c757d;
}

.firstGrid1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 15px;
  width: 97%;
  gap: 20px;
}

.firstGrid1 h5 {
  font-size: 15px;
  font-weight: 400;
  color: #2d995f;
  margin: 3px;
}

.TimeAt {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px;
  background-color: #ace7c766;
  align-items: center;
  /* width: 85%; */
  /* align-self: center; */
}

.timeWork {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.timeWork h6 {
  font-size: 13px;
  font-weight: 400;
  color: #343a40;
  margin: 3px;
}

.timeWork p {
  margin: 3px;
  font-size: 16px;
  font-weight: 700;
  color: #343a40;
}

.clockBtn {
  background-color: #227247;
  border-radius: 4px;
  border: 0;
  /* margin-top: 2px; */
  padding: 13px 26px;
  color: #ffffff;
  cursor: pointer;
}

.daFlex {
  display: flex;
  flex-direction: column;
}

.daFlex h6 {
  color: #6c757d;
  font-size: 13px;
  font-weight: 400;
  margin: 3px;
}

.daFlex p {
  margin: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #2d995f;
}
.moduleSelectorsCont {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
}
.moduleSelectors {
  /* width: 100%; */
  /* height: 170px; */
  background-color: #fff;
  max-width: 97%;
}
.moduleText {
  margin-left: 20px;
  margin-top: 20px;
  color: #000000;
  font-weight: 700;
}
.moduleText1 {
  color: #000000;
  font-weight: 700;
}
.settings {
  /* padding: 15px; */
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  width: 100%;
  /* border-radius: 5px; */
  /* padding: 20px; */
  gap: 10px;
  cursor: pointer;
  /* margin-bottom: 10px; */
  border-bottom: 0.5px solid #ced4da;
}
.spacing {
  /* margin-top: -30px; */
}
.moduleCont {
  display: flex;
  flex-direction: row;
  /* margin: 20px; */
  align-items: center;
  /* justify-content: center; */
}
.module1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  /* height: 150px; */
  border-radius: 5px;
  /* border: 1px solid #2D995F; */
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  /* max-width: 100%; */
}

.logoutBtn {
  cursor: pointer;
}
.modButton {
  display: flex;
  flex-direction: column;
}
.modButton1 {
  display: flex;
  flex-direction: column;
}
.modButton1 p {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin-top: -10px;
  margin-bottom: 10px;
}
.modButton h1 {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
}
.modButton1 h1 {
  color: #2d995f;
  font-size: 16px;
  font-weight: 700;
}
/* .module1:hover{
  transform: scale(1.05);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
} */
.modButton p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-top: -10px;
}

.ThFlex {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.firstGrid2 {
  display: flex;
  width: 100%;
  background-color: #ffffff;
}
.firstGrid22 {
  display: flex;
  flex-direction: column;
  /* background-image: url('../Assets/SecondGridBck.svg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  gap: 14px;
  /* background-color: #ffffff; */
  padding: 8px 15px;
}

.firstGrid2 h6 {
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
  margin: 3px;
}
.firstGrid22 h6 {
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
  margin: 3px;
}

.TomDiv {
  display: flex;
  flex-direction: column;
}

.TomDiv h6 {
  font-size: 12px;
  font-weight: 700;
  color: #2d995f;
  margin: 3px;
}

.TomDiv p {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  margin: 3px;
}

.TomDiv pre {
  font-size: 11px;
  font-weight: 400;
  color: #6c757d;
  margin: 3px;
}

.TomImg {
  width: 50px;
  height: 50px;
}

.klay {
  display: flex;
  gap: 15px;
  /* margin-bottom: 5px; */
}

.klay1 h5 {
  font-size: 14px;
  font-weight: 700;
  color: #343a40;
  margin: 2px;
}

.TomDiv {
  display: flex;
  flex-direction: column;
}

.klay1 {
  display: flex;
  gap: 15px;
}

.TomDiv1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.klay1 pre {
  margin: 2px;
  font-size: 11px;
  font-weight: 400;
  color: #6c757d;
  margin: 2px;
}

.firstGrid12 {
  display: flex;
  gap: 18px;
  align-items: center;
  background-color: #ffffff;
  padding: 15px 15px;
}

.EmpMonth {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.firstGrid12 img {
  width: 80px;
  height: 80px;
}

.firstGrid12 h5 {
  font-size: 14px;
  font-weight: 400;
  color: #2d995f;
  margin: 2px;
}

.firstGrid12 p {
  color: #6c757d;
  font-size: 12px;
  font-weight: 400;
  margin: 2px;
}

.BottomDivFlex h6 {
  color: #343a40;
  font-size: 12px;
  font-weight: 700;
  margin: 1px;
}

.BottomDivFlex p {
  color: #6c757d;
  font-size: 12px;
  font-weight: 400;
  margin: 1px;
}

.navbrd {
  display: flex !important;
  width: 100% !important;
}

.horinfodashbrd {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  /* height: 107px; */
  padding: 20px 30px 30px 20px;
  background-color: #ffffff;
  /* border: 1px solid #CED4DA; */
}

.usrcnr {
  display: flex;
  align-items: center;
}

.avtr {
  width: 62px;
  height: 62px;
  border-radius: 50px;
  /* margin-top: -5px; */
  background-color: #3770ab;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avtr1 {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  /* margin-top: -5px; */
  background-color: #2d995f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avtrs {
  width: 62px;
  height: 62px;
  border-radius: 50px;
  /* margin-top: -5px; */
  background-color: #3770ab;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bel {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
  /* padding-right: 8px; */
  margin-left: -3%;
}

.usrinfo {
  display: flex;
  justify-content: flex-end;
}

.usrnminf {
  margin-right: 25px;
  text-align: right;
}

.vrtlne {
  height: 54px;
  background-color: #adb5bd;
  width: 2px;
  margin-bottom: 10px;
}

.navdshbrd {
  border: 1px solid #ced4da;
  width: 30%;
}

.stinfo {
  padding-left: 20px;
}
.stinfosts {
  font-weight: 400;
  font-size: 12px;
  font-family: "inter";
  color: #6c757d;
  margin-top: -5px;
  /* margin-bottom: -17px; */
}
.stinfolvl {
  font-weight: 700;
  font-size: 20px;
  font-family: "inter";
  color: #000000;
  margin-top: -5px;
}

.usrnme {
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  margin-top: -5px;
}

.usrpstn {
  font-weight: 400;
  font-size: 12px;
  color: #6c757d;
  margin-top: -5px;
}
@media (max-width: 900px) {
  .appcontainer {
    /* height: 100vh; */
    width: 100%;
    /* min-width: 550px; */
  }

  .horinfodashbrd {
    /* min-width: 560px; */
    width: 100%;
    padding: 20px 30px 20px 20px;
    align-items: center;
  }
  /* .dashBoardCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
 
} */

  .firstGrid1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #ffffff; */
    /* padding: 10px 15px; */
    width: 100%;
    gap: 20px;
  }

  .firstGrid1 h5 {
    font-size: 15px;
    font-weight: 400;
    color: #2d995f;
    margin: 3px;
  }
  .moduleSelectors {
    background-color: #fff;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .moduleCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .module1 {
    /* display: flex; */
    /* flex-direction: row; */
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    padding: 10px 20px;
    gap: 20px;
    cursor: pointer;
    /* max-width: 100%; */
  }

  .stinfolvl {
    font-size: 15px;
  }
  .usrpstn {
    display: none;
  }
  .usrnme {
    display: none;
  }
  .avtr {
    width: 65px;
    height: 65px;
  }
  .avtrs {
    width: 45px;
    height: 45px;
  }
  .moduleText {
    margin-left: 50px;
    width: 100%;
    text-align: left;
  }
  .stinfo {
    padding-left: 0;
  }

  .modButton h1 {
    font-size: 16px;
    color: #000000;
  }

  .modButton p {
    font-size: 14px;
  }
}
