@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

.moduleselector {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 20px;
    background: url('../../assets/promix/background.png') no-repeat center center fixed;
    background-size: cover;
    /* height: 100vh; */
    /* background-color: rgb(0, 19, 0); */
    /* overflow-y:hidden; */
  }

  .backgroundoverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: url('../../assets/promix/background.png') no-repeat center center fixed;
    background-size: cover;
    z-index: 1;
  }
  
  .backgroundoverlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(1, 12, 0, 0.959); /* Adjust the last value for transparency */
    z-index: 2;
    /* background-size: cover; */
    pointer-events: none; /* Ensures that the overlay does not interfere with user interactions */
  }
  
  .moduleselector > *:not(.background-overlay) {
    position: relative;
    z-index: 3;
  }
  
  header {
    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
    padding: 10px;
    /* background-color: rgba(255, 255, 255, 0.8); */
  }
  
  header h1, header h2 {
    margin: 0;
  }

  .leftmodule {
    margin: 0;
  }

  .rightmodule {
    margin: 0;
  }
  
  .logout, .admin {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modulecontainer {
      /* margin-top: 20px; */
      /* padding-left: 60px;
      padding-right: 60px; */
      display: flex;
      justify-content: space-around;
      /* gap: -20px; */
  }

  @media (max-width: 768px) {
    .modulecontainer {
      display:list-item
    }
  }

  .admindiv {
      /* margin-top: 20px; */
      padding-left: 580px;
      padding-right: 580px;
      justify-content: center;
  }

  .texth2
  {
    font-size: 15px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .upper1 {
    display: flex;
    justify-content: center;
  }

  .upplft {
    width: 100%;
    text-align: center;
    /* justify-content: left; */
    align-items: center;
    /* background-color: white; */
    /* grid-template-columns: repeat(2, 1fr); */
    /* gap: 20px; */
  }

  .theBody{
    margin-bottom: 10px;
  }

  .theBody p{
    font-weight: 700;
    color: #000;
    font-size: 20px;
  }

  .userText{
    font-weight: 900;
    color: #000;
    font-size: 40px;
  }
  .brookesText{
    font-weight: 600;
    color: #272727;
    font-size: 20px;
  }

  .upprht {
    /* width: 20%; */
    /* background-color: white; */
    /* grid-template-columns: repeat(2, 1fr); */
    /* gap: 20px; */
  }
  
  .selectmodule {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
    /* padding: 40px; */
    border-radius: 50px;
    /* background-color: rgba(255, 251, 251, 0.336); */
    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); */
    /* width: 200px; */
    /* height: 150px; */

  }
  
  .moduleicon {
    width: 35px;
    height: 35px;
    /* margin-bottom: 10px; */
  }

  .moduleicon122 {
    width: 170px;
    height: 130px;
    /* margin-bottom: 10px; */
  }

  .moduleicon1 {
    width: 25px;
    height: 25px;
    /* margin-bottom: 10px; */
  }

  .mainshp1
  {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgb(14, 82, 0, 0.89);
    height: 90px;
    width: 400px;
    margin-bottom: 18px;
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
  }
  .mainshp12
  {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgb(221, 84, 5, 0.89);
    height: 90px;
    width: 400px;
    margin-bottom: 18px;
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
  }

  .mainshp11
  {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgb(8, 46, 1);
    height: 120px;
    width: 300px;
    margin-bottom: 18px;
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
  }
  .mainshp13
  {
    display: flex;
    margin-top: 20px;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgb(14, 82, 0);
    height: 120px;
    width: 300px;
    margin-bottom: 18px;
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
  }

  .uppcircless1 
  {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgb(14, 82, 0); /* Blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  }

  .uppmainshp1
  {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgb(14, 82, 0);
    height: 90px;
    width: 400px;
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    margin-left: 20px;
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
    /* display: none; */
  }

  .uppinstxt
  {
    width: 100px;
    /* display: none; */
    /* padding: 5px; */
  }
  .uppinstxt h2
  {
   font-size: 20px !important;
   color: white !important;
   font-weight: 700 !important;
  }

  .uppinshp
  {
    /* margin-left: 70px; */
    width: 20px;
  }

  .uppcircless 
  {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(2, 2, 2, 4);
  }


  .mainshp2
  {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgba(4, 4, 100, 0.89);
    height: 90px;
    width: 400px;
    /* margin-bottom: 18px; */
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
  }
  .mainshp3
  {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgb(0, 15, 44, 0.89);
    height: 90px;
    width: 400px;
    margin-bottom: 18px;
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
  }
  .mainshp4
  {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgba(13, 114, 145, 0.89);
    height: 90px;
    width: 400px;
    margin-bottom: 18px;
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
  }
  .mainshp5
  {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgb(221, 84, 5, 0.89);
    height: 90px;
    width: 400px;
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
  }

  .mainshp6
  {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 100px;
    color: white;
    background-color: rgb(201, 5, 5, 0.89);
    height: 90px;
    width: 400px;
    font-size: 12px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    /* transition: transform 0.3s; */
    /* gap: 200px; */
    transition: transform 0.3s;
    cursor: pointer;
  }

  .mainshp1:hover, .mainshp2:hover, .mainshp3:hover, .mainshp11:hover, .mainshp12:hover,  .mainshp13:hover,
  .mainshp4:hover, .mainshp5:hover, .mainshp6:hover, .uppmainshp1:hover{ 
    transform: scale(1.05);
    /* background-color: rgb(2, 48, 2); */
  }

  .circless2:hover, .circless1:hover, .circless3:hover, .circless12:hover,
  .circless4:hover, .circless5:hover, .circless6:hover, 
  .uppcircless1:hover, .logcircless:hover{ 
    transform: scale(1.05);
    /* background-color: rgb(2, 48, 2); */
  }
  
  .instxt
  {
    width: 315px;
    padding: 10px;
  }
  .instxt h2
  {
    color:white !important;
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .instxt p{
    color:white !important;
    padding-bottom: 10px;
    padding-top: -30px;
  }

  .select121
  {
    background-color: rgba(0, 37, 17, 0.829);
    padding: 15px;
    color: white;
    border-radius: 10px;
  }

  .inshp
  {
    /* margin-left: 70px; */
    width: 50px;
  }

  .loginshp
  {
    margin-right: 120px;
    width: 50px;
  }
  
  .circless 
  {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(2, 2, 2, 4);
  }

  .logcircless 
  {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(2, 2, 2, 4);
    cursor: pointer;
  }

  .circless1 
  {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(14, 82, 0); /* Blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  }
  .circless12 
  {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(221, 84, 5, 0.89); /* Blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  }

  .logcircless1 
  {
 
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: rgb(201, 5, 5, 0.89); /* Blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  }
  .circless2 
  {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(4, 4, 100); /* Blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  }
  .circless3 
  {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(0, 15, 44); /* Blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  }
  .circless4 
  {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(5, 154, 199); /* Blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  }
  .circless5 
  {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(221, 84, 5); /* Blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  }
  .circless6 
  {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(201, 5, 5); /* Blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  }

  .fontsfammm {
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
  }

  .wlcname {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 20px;
    font-weight: 150px;
  }
  

  /* style={{ backgroundColor: module.color }} */