*, 
::after,
::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root{
    --white: #FFFFFF;
}

.loginlinkclick:hover {
    color: #FFFFFF;
    font-size: 18px;
}

.loginlinkclick {
    cursor: pointer;
    font-size: 14px;
    color: #FFFFFF;
}



.newSignupBody{
    width: 100%;
    /* background-image: url('../../assets/NewSignupImg/promiximgbg.png');    */
    background-image: linear-gradient(to top left, green, rgb(73, 196, 2), green);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    /* padding: 30px 0 50px 0; */
}
.signInNewSignupBody{
    
    height: auto;
    padding: 30px 0 50px 0;
}
.newLoginBody{
    height: 100vh;
}

.newSignContainer{
    width: 85%;
}
.logo{
    width: 100px;
    height: 50px;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.newSignSection{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    color: var(--white);
}
.newSignEndToEnd {
    /* width: 100%; */
    /* max-width: 650px; */
}
.newSignEndToEnd h1{
    /* max-width: 400px; */
    color: var(--white);
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 30px;
}
.newSignObject img{
    width: 80%;
    /* height: 600px; */
    object-fit: contain;
}

.formHead{
    width: 100%;
    text-align: center;
    color: var(--white);
    margin-bottom: 30px;
}
.formHead h1{
    font-weight: 800;
    color: var(--white);
} 
.formHead p{
    color: var(--white);
}
.loginFormHead{
     margin-top: 1rem;
     max-width: 90%;
}
.mainForm form{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mainForm .inputs{
    background-color: unset;
    border: 1px solid var(--white);
    color: var(--white) !important;
}
.inputs::placeholder{
    color: var(--white);
}

.flexSect{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.flexSect .inputs{
    width: 100%;
    min-width: 300px;
}
.inputs i{
    color: white;
}
.mainForm{
    width: 100%;
}
.newbtns{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.newbtns  button{
    width: 60%;

}

.loginForm{
    max-width: 90%;
}
.loginFormHead{
    margin-top: 10px;
    max-width: 80%;
}
@media(max-width:900px){

    .newSignupBody{
        width: 100%;
        /* background-image: linear-gradient(to top left, green, rgb(73, 196, 2), green); */
        /* color: var(--white); */
        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
        /* height: auto; */
        /* padding: 30px 0 50px 0; */
    }
    .signInNewSignupBody{
        
        /* height: 100; */
        /* padding: 15px 0 50px 0; */
    }
    .newLoginBody{
        height: 100%;
    }
    
    /* .newSignContainer{
        width: 85%;
    } */
    .logo{
        width: 100px;
        height: 50px;
    }
    .img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .newSignSection{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--white);
        justify-content: center;
        width: 100%;
    }

    .newSignEndToEnd h1{
        /* max-width: 400px; */
        /* color: var(--white); */
        font-weight: 800;
        margin-top: 30px;
        font-size: 25px;
        margin-bottom: 30px;
    }
    .newSignObject {
        display: none;
    }
    .newSignObject img{
        width: 100%;
        /* height: 600px; */
        object-fit: contain;
    }

    .formHead{
        width: 100%;
        margin: 30px 0;
    }
    .formHead h1{
        font-weight: 800;
        color: var(--white);
        font-size: 28px;
    } 
    .formHead p{
        color: var(--white);
    }
    .mainForm form{
        /* display: flex; */
        /* flex-direction: column; */
        gap: 20px;
    }
    
    .flexSect{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .loginForm{
        min-width: 100%;
        max-width: 100%;
        padding-bottom: 50px;
    }
    .loginFormHead{
        margin-top: 10px;
        min-width: 100%;
        max-width: 100%;
    }
    .loginForm .inputs{
        width: 100%;
    }

    .newbtns  button{
        width: 100%;
    
    }
    

}