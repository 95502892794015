@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
    background-color: white;
    /* padding: 50px; */
}

.container {
    width: 100%;
    max-width: 210mm;
    margin: auto;
    padding: 20mm;
    justify-content: center;
    background-color: var(--White);
}

.title {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 5px;
}

.heading {
    flex-grow: 1;
    font-weight: 700;
}
.heading h2{
   font-weight: 900;
}

.heading h1, .heading h3, .heading h4, .heading h2, .heading p {
    margin: 0;
    padding: 0;
}

.title img {
    margin-right: 20px;
    height: 100px;
    width: 100px;
}

hr {
    border: 1px solid black;
    margin: 0;
}

.smallheader {
    text-align: center;
    border: 1px solid black;
    font-size: 19px;
    margin: 10px 0;
    padding: 5px;
}

.nameheader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.namespace {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.questionname p, .details h2, .details h3 {
    margin: 0;
    padding: 0;
    font-size: 10px;
}
.questionname h5 {
    font-size: 14px;
  
}

.details h6{
    font-weight: 700;
    /* margin-left: 5px; */
    /* text-decoration: underline; */
}

.passport img {
    height: 100px;
    width: 100px;
    border: 1px solid black;
}

.magnify111 {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    border-collapse: collapse;
    background-color: none;
}

.magnify111, .reportTable th {
    border: 1px solid black;
    padding: 5px;
    background-color: none;
    font-size: 15px;
    color: rgb(0, 0, 0);
}

.magnify111, .reportTable td {
    border: 1px solid black;
    padding: 5px;
    background-color: none;
    font-size: 15px;
    color: black;
}

.reportTable td:nth-child(2) {
    text-align: left;
    padding-left: 10px;
}

.longth {
    width: 400px;
    text-align: left !important;
    font-size: 12px !important;
}

.left111 {
    text-align: left !important;
}

.right111 {
    text-align: right !important;
    width: 120px !important;
    
}


.gradeheaders {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.gradetable {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 15px;
}

.grades {
    text-align: center;
    border: 1px solid black;
    font-weight: bold;
    padding: 5px;
    margin-bottom: 5px;
}

.reportTable td:nth-child(3) {
    text-align: left;
}

.signature {
    text-align: center;
    margin-top: 200px;
}

.keytable {
    text-align: center;
    margin-top: 20px;
}

.keytable .magnify111 {
    margin: auto;
    border-collapse: collapse;
}

.reportTable th {
    text-align: left;
    padding: 5px;
}

.keytexts {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.thekeys {
    margin-left: 20px;
}

.printbutton {
    justify-content: center;
    background-color: #198754;
    color: white;
    align-items: center;
    height: 40px;
    width: 100px;
    border-radius: 4px;
}

.printbutton:hover {
    
    background-color: #14422d;
    
}
.footer {
    text-align: center;
    font-style: italic;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-top: 20px;
}

@media print {
    @page {
        size: A4;
        margin: 20px;
    }
    body {
        margin: 0;
        padding: 0;
    }
    .container {
        padding: 20px;
        margin: 0;
        width: 100%;
        max-width: 100%;
        background-color: var(--White);
    }
    .title img {
        height: 120px;
        width: 120px;
    }
    .heading h1 {
        font-size: 24px;
    }
    .heading h3 {
        font-size: 18px;
    }
    .heading h4, .heading p {
        font-size: 14px;
    }
    .smallheader {
        font-size: 16px;
        margin: 10px auto;
    }
    .nameheader {
        margin-bottom: 10px;
    }
    .details h2 {
        font-size: 18px;
    }
    .details h3 {
        font-size: 14px;
    }
    .magnify111 {
        margin-top: 10px;
        font-size: 12px;
        background-color: white;
    }
    .reportTable th, td, tr {
        background-color: white;
        color: black;
    }
    .gradeheaders {
        margin-top: 20px;
    }
    .gradetable {
        font-size: 12px;
    }
    .signature {
        margin-top: 160px;
    }
    .keytable {
        margin-top: 10px;
    }
    .footer {
        font-size: 14px;
        margin-top: 20px;
    }

    .printbutton {
        display: none;
    }
}
