/* .newDashbody{
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    padding-top: 20px;
}
.newDashCont{
    width: 90%;
 
}
.navSectionHead{
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navSectionHead h4{
    font-weight: 700;
    font-size: 20px;
    margin-top: 2px;
} */

.navSection {
  width: 100% !important;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 10px 0 10px 115px;
  position: fixed;
  /* margin-top: 10px; */
  left: 0;
  right: 0;
  background-color: #3770ab;
  /* background-color: #2d995f; */
  /* border-top: 2px solid black; */
  border-top: 2px solid rgba(172, 231, 199, 0.05);
  z-index: 10;
}
.navSectionNavCont {
  width: 100%;
  /* max-width: 85%; */
  /* display: flex;
    justify-content: flex-start; */
}
.navSectionNav {
  display: flex;
  gap: 5px;
  /* justify-content: space-between; */
  /* margin-left: -70px; */
  /* display: grid;
    grid-template-columns: repeat(8, 1fr); */
  /* column-gap: 0.2em; */
  align-items: left;
  text-align: left;
  justify-content: left;
  align-content: left;
  justify-items: left;

  /* display: flex;
      justify-content: space-between;
      align-items: center; */
  width: 100%;
  /* max-width: 70%; */
}
.Dropdowns {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  /* padding: 15px; */
  width: 100%;
  text-align: left;
  /* background-color: gray; */
}
.DropdownMenu {
  z-index: 12 !important; /* Ensure dropdown menu has the highest z-index */
  position: absolute;
  background-color: white;
}
.DropdownMenu a {
  font-size: 14px !important;
}
/* .dropFonts{
      font-size: 14px !important;
  } */
.Dropdowns:hover {
  background-color: transparent !important;
}
.Dropdowns .active {
  /* background-color: green;
      color: var(--White); */
}
.Dropdowns span {
  font-size: 16px;
  font-weight: 400;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* gap: 5px; */
  width: 100%;
  color: white;
  /* min-width: 150px; */
}
.Dropdowns span img {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

/* Target the default dropdown indicator */
.dropdown-toggle::after {
  /* Add your styles here */

  all: unset !important;
  display: none !important; /* Hide the default arrow if you want to replace it */
}
.divider {
  background-color: rgb(228, 226, 226) !important;
  height: 2px; /* Optionally adjust the height */
}

/* .Dropdowns .dropdown-toggle {
      position: relative; 
      padding-right: 25px;
  } */

/* .Dropdowns .dropdown-toggle::before {
      content: ''; 
      position: absolute;
      right: 10px; 
      top: 50%;
      transform: translateY(-50%);
      width: 10px; 
      height: 10px;
      border-width: 2px;
      border-style: solid;
      border-color: #000 transparent transparent transparent; 
  } */

.Dropdowns:focus,
.Dropdowns.show,
.Dropdowns:active {
  background-color: unset !important;
  color: black !important;
  box-shadow: none !important;
  outline: none !important;
}

/* Optional: Ensure the dropdown items don't show active state */
/* .dropdown-item:focus, */
/* .dropdown-item:hover */
.dropdown-item:active {
  background-color: green !important;
  /* color: inherit; Optional: keep the text color the same */
}

/* Mobile Styles */
@media (max-width: 992px) {
  .navSectionNav {
    display: none; /* Hide the menu by default on mobile */
    flex-direction: column;
    width: 100%;
  }

  .navSectionNav.show {
    display: flex; /* Show the menu when the hamburger is clicked */
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    display: block;
  }

  .hamburger div {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 3px 0;
  }
}

@media (max-width:950px) {
  .navSection {
    display: none;
  }
}
