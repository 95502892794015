#invoice {
    padding: 0px;
}

.invoice {
    position: relative;
    background-color: #fff;
    min-height: 680px;
    padding: 15px;
}

.invoice header {
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--green);
}

.textend {
    padding: 20px;
}

.container {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 25px;
    margin-top: 15px;
    padding-bottom: 50px;
}

.colcompanydetails {
    text-align: right;
    padding-right: 40px;
    color: var(--green);
    align-items: right;
    align-content: right;
}

.colorgreenh2 {
    color: var(--green);
}

.rowcontacts {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 50;
}

.headercolor {
    color: var(--green);
    font-size: 40px;
    /* font-weight: 200; */

}

.colinvoicedetails {
    align-items: right;
    align-content: right;
    text-align: right;
}

.textcent {
    background-color: var(--green);
    color: white;
    font-size: 1.6em;
    text-align: center;
    height: 70px;
    width: 60px;
    border-bottom: 1px solid #fff;
}

.textupp {
    color: var(--green);
    font-size: 20px;
    padding-left: 15px;
}

.textbackc {
    background-color: #eee;
    border-bottom: 1px solid #fff;  
}

.textdwn {
    color: black;
    font-size: 13px;
    padding-left: 15px;
}

.textcent1 {
    background-color: var(--green);
    color: black;
    font-size: 20px;
    text-align: center;
    height: 70px;
    width: 20px;
    border-bottom: 1px solid #fff;
}

.textcent1213 {
    background-color: #eee;
    color: black;
    font-size: 20px;
    text-align: center;
    height: 70px;
    width: 20px;
    border-bottom: 1px solid #fff;
}

.textleft {
    background-color: #eee;
    color: black;
    font-size: 20px;
    text-align: left;
    height: 70px;
    /* width: 20px; */
    border-bottom: 1px solid #fff;
    padding-left: 10px;
    font-weight: 20;
}

.textleft222 {
    /* background-color: #eee; */
    color: black;
    font-size: 18px;
    text-align: right;
    height: 40px;
    /* width: 20px; */
    border-bottom: 1px solid #161616;
    padding-right: 10px;
    font-weight: 20;
}

.textleft222ggg {
    /* background-color: #eee; */
    color: black;
    font-size: 18px;
    text-align: right;
    height: 40px;
    /* width: 20px; */
    border-bottom: 1px solid var(--green);
    padding-right: 10px;
    font-weight: 20;
}

.thanktext {
    font-size: 25px;
    color: black;
}

.noticetext {
    height: 60px;
    border-left: 6px solid var(--green);
    background-color: #e7f2ff;
    padding: 10px;
}

.footertextsss {
    border-top: 1px solid var(--green);
    padding: 15px;
    text-align: center;
}

.textleft22212 {
    /* background-color: #eee; */
    color: var(--green);
    font-size: 18px;
    text-align: right;
    height: 40px;
    /* width: 20px; */
    /* border-bottom: 1px solid #007bff; */
    padding-right: 10px;
    font-weight: 20;
}

.textright {
    background-color: #eee;
    color: black;
    font-size: 20px;
    text-align: right;
    font-weight: 20;
    height: 70px;
    /* width: 20px; */
    border-bottom: 1px solid #fff;
    padding-right: 10px;
}

.textright1 {
    background-color: #ddd;
    color: black;
    font-size: 20px;
    text-align: right;
    font-weight: 20;
    height: 70px;
    /* width: 20px; */
    border-bottom: 1px solid #fff;
    padding-right: 10px;
}

.textright22 {
    background-color: var(--green);
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: right;
    font-weight: 20;
    height: 70px;
    /* width: 20px; */
    border-bottom: 1px solid #fff;
    padding-right: 10px;
}

.colinvoiceto {
    align-items: left;
}

.invoice .company-details {
    text-align: right;
}

.invoice .company-details .name {
    margin-top: 0;
    margin-bottom: 0;
}

.invoice .contacts {
    margin-bottom: 20px;
}

.invoice .invoice-to {
    text-align: left;
}

.invoice .invoice-to .to {
    margin-top: 0;
    margin-bottom: 0;
}

.invoice .invoice-details {
    text-align: right;
}

.invoice .invoice-details .invoice-id {
    margin-top: 0;
    color: var(--green);
}

.invoice main {
    padding-bottom: 50px;
}

.invoice main .thanks {
    margin-top: -100px;
    font-size: 2em;
    margin-bottom: 50px;
}

.invoice main .notices {
    padding-left: 6px;
    border-left: 6px solid var(--green);
    background: #e7f2ff;
    padding: 10px;
}

.invoice main .notices .notice {
    font-size: 1.2em;
}

.tableeepd {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px;
}

.fullmargin {
    padding-left: 40px;
    padding-right: 40px;
}
.invoice table td,
.invoice table th {
    padding: 15px;
    background: #eee;
    border-bottom: 1px solid #fff;
}

.invoice table th {
    white-space: nowrap;
    font-weight: 400;
    font-size: 16px;
}

.invoice table td h3 {
    margin: 0;
    font-weight: 400;
    color: var(--green);
    font-size: 1.2em;
}

.invoice table .qty,
.invoice table .total,
.invoice table .unit {
    text-align: right;
    font-size: 1.2em;
}

.invoice table .no {
    color: #fff;
    font-size: 1.6em;
    background: var(--green);
}

.invoice table .unit {
    background: #ddd;
}

.invoice table .total {
    background: var(--green);
    color: #fff;
}

.invoice table tbody tr:last-child td {
    border: none;
}

.invoice table tfoot td {
    background: 0 0;
    border-bottom: none;
    white-space: nowrap;
    text-align: right;
    padding: 10px 20px;
    font-size: 1.2em;
    border-top: 1px solid #aaa;
}

.invoice table tfoot tr:first-child td {
    border-top: none;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    /* background-clip: border-box; */
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: .25rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}

.invoice table tfoot tr:last-child td {
    color: var(--green);
    font-size: 1.4em;
    border-top: 1px solid var(--green);
}

.invoice table tfoot tr td:first-child {
    border: none;
}

.invoice footer {
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 1px solid #aaa;
    padding: 8px 0;
}

@media print {
    .invoice {
        font-size: 11px !important;
        overflow: hidden !important;
    }
    .invoice footer {
        position: absolute;
        bottom: 10px;
        page-break-after: always;
    }
    .invoice>div:last-child {
        page-break-before: always;
    }

    .toolbarhiddenprint {
        display: none;
        /* display: hidden; */
    }

    .textend {
        display: none;
        /* display: hidden; */
    }

    .container {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .fullmargin {
        padding-left: 20px;
        padding-right: 20px;
    }

}

.invoice main .notices {
    padding-left: 6px;
    border-left: 6px solid var(--green);
    background: #e7f2ff;
    padding: 10px;
}