*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: #6C757D;
    /* --Background:rgba(172, 231, 199, 0.05); */
}
.header2{

}
.topPadding{
    width: 100%;
    padding-top: 1rem;
}

.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    /* border-radius: 12px; */
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: 700;
  }
.formSection{
    width: 100%;
    /* padding-left: 20rem; */
    /* background-color: #4b9483; */
    padding-right: 3rem;
}
.newWidth{
    padding: 0 7rem 0 7rem;
    padding-top: 5rem;
}
.formSectionHeader{
  /* width: 100%; */
  /* padding-top: 40px; */
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSection p{
    font-size: 14px;
}
.formSectionHeader h3{
    /* color: var(--Light-green); */
    font-size: 20px;
    font-weight: 700;
}
.formSectionHeader h4{
    font-weight: 700;
}
.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    border-radius: 12px;
    width: 100%;
}
.analysis{
    padding: 18px 100px 14px 32px;
    background-color: var(--Deep-green);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    /* color: var(--White); */
}
.analysisCont h5,
.analysisCont p{
    color: var(--White);
    margin: 0;
}
.perceCont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    padding-top: 10px;
    /* color: var(--White); */
}
.percent{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    /* color: var(--White); */
}



.flex1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bodyContainer button{
    padding: 12px 30px;
    border-radius: 4px;
}
.whiteBtn{
    color: var(--blackGray);
    border: 1px solid var(--blackGray);
}
.bodyContainer{
    /* padding:2rem 3rem 0 20rem ; */
    /* padding-right: ; */
}
.main{
    /* border: 1px solid var(--Light-green); */
    border-radius: 8px;
}
.header1{
    width: 100%;   
    /* padding: 20px 21px; */
    /* border-bottom: 1px solid var(--Light-green); */

}
.actionBtns{
    display: flex;
    gap: 24px;
}
.header2{
    background-color: var(--White);
    /* display: flex; */
    padding: 20px 20px;
    /* gap: 31px; */
}
.formContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.formCont{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* justify-content: space-between; */
    gap: 20px   ;
}
.formGroup{
    width: 100%;
}
.formGroup label{
    font-size: 12px;
}
.formGroup input,
.formGroup select{
    margin: 0;
    font-size: 12px;
    border-radius: 4px;
    padding: 14px 20px;
    /* width: 80%; */
}
.formFlex{
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    justify-content: center;
    gap: 10px;
}
.formFlex2 label{
    padding: 0 !important;
}
.formFlex label{
    padding-top: 10px;
}




.whiteBtn{
    color: var(--blackGray);
    border: 1px solid var(--blackGray);
}
.bodyContainer{
    /* padding:2rem 3rem 0 20rem ; */
    /* padding-right: ; */
}
.main{
    /* border: 1px solid var(--Light-green); */
    border-radius: 8px;
}
.header1{
    width: 100%;   
    /* padding: 20px 21px; */
    /* border-bottom: 1px solid var(--Light-green); */

}
.actionBtns{
    display: flex;
    gap: 24px;
}
/* .header2{
    display: flex;
    padding: 12px 20px;
    gap: 31px;
} */
.discrp{
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
}
.discrp i{
    font-size: 24px;
}
.pagenate{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 71px;
    padding: 17px 0 21px 0;
}
.transparent{
    background-color: transparent;
    color: var(--Black);
    border: none;
}
.pagenate button{
    padding: 5px 20px;
}
.table{
    padding: 20px;
}
table .actions{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    /* margin-top: 5rem; */
}
table .action{
    padding: 5px 15px;
}
.tableHead th{
    background-color: var(--Deep-green);
    color: var(--White);
    font-size: 14px;
    font-weight: 600;
}