/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 10px;
    font-weight: 400;
} */

/* body {
    background-color: #f0f0f0;
} */

.receipt {
    width: 21cm;
    height: 29.7cm;
    padding: 3rem 2rem 0 2rem; /* Reduced padding */
    margin: 0.6cm auto; /* Reduced margin */
    background-color: #fff;
    box-shadow: 0 0.4cm 0.8cm rgba(216, 21, 21, 0.1);
    font-size: 10px;
}


.logo {
    display: flex;
    align-items: center; /* Align items vertically centered */
    /* padding-left: 80%; */
    width: 100%;
    justify-content: right;
}

.logo img {
    width:60px; /* Adjust size as needed */
    height: 60px; /* Adjust size as needed */
}

.logo h3 {
    font-size: 20px; /* Adjust font size as needed */
    margin: 0;
    padding: 0;
}


.container {
    /* padding-top: 5%;
    padding-left: 1%; */
}

.truncate {
   
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
    /* font-weight: 700 !important; */
    color: #000000 !important;
    /* white-space: nowrap; */

}

.container h3 {
    font-weight: 900;
    color: #000000;
    font-size: 20px;
}

.second {
    /* padding-left: 2%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 25%; */
    width: 100%;
}

.second h5 {
    font-weight: 400;
    margin: 0;
}

/* .second p {
    font-weight: bolder;
    margin: 0;
} */

.first {
    font-weight: bold;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    /* gap: 3px; */
    width: 50%;
}


.first p {
    font-size: 12px;
    font-weight: 500;
}
.fifth p {
    font-size: 12px;
    font-weight: 500;
}

.last {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 25px;
    width: 50%;
    /* margin-top: 40px; */
    /* padding-left: 13%; */
}

.last1 {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    padding-left: 60%;
}

.fifth {
    display: flex;
    flex-direction: column;
    width: 25%;
    /* gap: 3px; */
}

.fifth h5 {
    font-size: 10px;
}

.fifth .header {
    /* margin-top: 40px; */
}

.last p {
    font-weight: bold;
    padding-bottom: 20px;
}

.last h5 {
    font-size: 10px;
}

.last1 p {
    font-weight: bold;
}

.last1 h5 {
    font-size: 10px;
}

.fourth {
    text-align: right;
    
    /* font-weight: bold; */
}

.fourth h5 {
    font-size: 14px;
    /* text-align: right; */
    /* font-weight: bold; */
}

.fourth1 {
    text-align: right;
    gap: 30px;
}

.third h5 {
    font-weight: 700;
    font-size: 14px;
    /* font-weight: bold; */
}

.thTable{
    width: 100%;
    margin-top: 10px;
    font-weight: 100!important;
}
.thTable thead{
    color: grey;
    font-style: italic;
    background-color: rgb(218, 218, 218);
    border-top: 2px solid grey;
    border-bottom: 2px solid grey;
    font-size: 10px;
    font-weight: lighter;
    /* padding: 20px 0; */
}
.thTable th{
    padding: 3px 0;
}
.thTable td{
    padding: 8px 0;
     color: black;
     /* font-weight: ; */
}
.thTable tbody{
    color: black;
    font-style: italic;
    font-size: 10px;
    font-weight: 530;
    /* padding: 0 90px; */
    border-bottom: 1px solid rgb(184, 184, 184);
}
.boldtable{
    font-weight: bold;
    text-align: right;
}
.tablebodyCnt{
border-bottom: 1px solid rgb(184, 184, 184);
/* padding:  90px 0!important; */
}

.tablebodyCntBottom{
    /* margin-top: 200px; */
}

.footerfirstdiv{
    display: flex;
    flex-direction: column;
}
.footerfirstdiv p{
    margin: 0;
}

.spantxt{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.spantxt1{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}
.balanceTxt{
    font-size: 16px;
    color: rgb(221, 217, 217);
    font-weight: 650;
    text-align: right;
}
.balanceTxtNum{
    font-size: 17px;
    color: black;
    font-weight: 700;
    text-align: right;
}


.third1 h5 {
    font-weight: bold;
}

.custom-hr {
    border: none;
    border-top: 5px solid gray;
    max-width: 100%;
    width: 80%;
    margin: 20px auto;
}

.secondDiv {
    /* padding-left: 2%; */
}

.secondDiv p {
    font-weight: bold;
    padding-bottom: 10px;
    font-size: 10px;
}

.secondDivsecond {
    font-weight: bold;
}

.rightText {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
}

.rightText .pair {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 5px;
    text-align: right;
    align-items: flex-start;
}

.parag {
    font-weight: bold;
    text-align: left;
}

.lastDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    /* padding: 0 2%; */
}

@media print {
    .toolbarhiddenprint {
      display: none;
    }
}