.thcontainer{
    /* border: 1px solid black; */
    align-items: center;
    justify-content: center;
    align-self: center;
    align-content: center;
    width: 80%;
    font-family: Arial, Helvetica, sans-serif;
    margin: auto;
    /* overflow-x: hidden!important; */
}
.topHead{
    text-align: center;
}
.company{
    font-size: 20px;
    color: black;
    margin-bottom: 10px;
}
.cashflow{
    color: rgb(48, 157, 194);
    margin-top: 0;
}
.topRow{
    text-align: right;
    margin-bottom: 30px;
}
.toplabl{
    padding-right: 5px;
}
.topRow1{
    margin-bottom: 5px;
}
.firstInput{
    border: 0;
    outline: none;
    padding: 0px 35px;
    font-weight: 1000;
}
.firstInput:hover{
    border: 0!important;
    /* border: none; */
}

.firsttable{
    width: 100%;
    border-collapse: collapse;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5%;
}
.operate{
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 550;
}
.notOperate{
    padding-left: 10px;
}
.notDOperate{
   font-size: 19px;
   /* font-weight: 550; */
   text-align: left;
    padding-left: 10px;
    /* margin-bottom: 0.5%; */
}
.notDOperate1{
    font-size: 19px;
    font-weight: 550;
    text-align: left;
    padding-left: 10px;
    /* background-color: ; */
}

.boxInput{
    text-align: right;
    margin: 0;
    padding-right: 20px;
    border-radius: 0;
    border: 0!important;
}
.boxInput1{
    text-align: right;
    margin: 0;
    padding-right: 17px;
    border-radius: 0;
    border: 0!important;
    color: red;
}
.boxInput2{
    text-align: right;
    margin: 0;
    padding-right: 17px;
    border-radius: 0;
    border: 0;
    font-size: 18px;
    /* border-top: 1px solid black; */
}





.NotboxInput{
    border: 0;
    text-align: right;
    outline: none;
    
}
.noBorder{
    border: 0;
    outline: none;
    background-color: lightgray;
}
.noBorder1{
    background-color: lightblue;
    border: 0;
    outline: none;
}

.noBorder:hover{
    border: 0!important;
}

.tbleRw{
background-color: rgb(72, 117, 201);
font-size: 20px;
font-weight: 600;
color: azure;
/* height: 60px; */
/* padding-top: 30px;
padding-bottom: 30px; */
}
.dRow{
    text-align: left;
    padding-left: 20%;
    /* font-weight: 0; */
}
.solos{
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
    font-weight: 550;
    font-size: 16px;
    /* height: 35px; */
}
.Hsolos{
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
    /* font-weight: 100; */
}
.Tsolos{
    background-color: lightgray;
    margin-bottom: 0.5%;
}
.Thsolos{
    background-color: lightblue;
    margin-bottom: 0.5%;
}
.lastflex{
    display: flex;
    /* margin-right: -89px!important; */
    padding-right: 0;
    text-align: right;
    height: 30px;
    gap: 5px;
}
.lastflex, p{
    padding: 0;
    margin: 0;
    margin-top: 7px;
}
.endInput{
    border: 0;
    outline: none;
    background-color: lightgray;
    /* padding-top: -20px;*/
    /* height: 35px; */

}
.topRow2{
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    
}
.topRow2 p{
    text-align: right;
    margin: 0;
    background-color: rgb(189, 189, 189);
    width: 100px;
    padding-right: 18px;
}
.tInput{
    border: 0;
    outline: none;
}
.tInput:hover{
    border: 0;
    outline: none;
}