*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
}
.fetchedTxt{
  font-size: 16px;
  font-weight: 500;
}

.finishedbodyCont{
  width: 100%;
  padding: 0 2rem 0 21rem;
  /* padding-right: 4rem; */


}
.mainTable table td{
  /* padding:  0 ; */
align-items: center;
/* display: none !important; */
/* background-color: red !important; */

  /* display: none; */
}

.btnStyle {
  padding: 20px 0 0 0 ;
  margin-bottom: 20px;
}

.btndivStyle {
  display: flex; 
  gap: 20px;
}

.endded {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
}
.topPadding{
  width: 100%;
  padding-top: 1rem;
}
.formSecCont{
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSecCont h3{
  color: #343A40;
  font-size: 20px;
  font-weight: 700;
}

.shadow {
  box-shadow: 0 0 5px rgb(238, 236, 236);
}

.applycntnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.loandethead{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 30px; */
}
.loandethead .formIntBtn{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  gap: 10px;
}

.formIntBtn .btn1{
  background-color: var(--White);
  border: 1px solid rgb(187, 183, 183) !important;
  color: var(--Black);
  font-size: 14px;
  font-weight: 600;
}
.formIntBtn .btn1:hover{
  border: 2px solid var(--Light-green) !important;
  color: var(--Light-green);
  background-color: transparent;
}
.formIntBtn .btn2{
  font-size: 14px;
}

/* .loandethead button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: var(--Light-green);
  font-size: 16px;
  gap: 5px;
} */
.loandethead button:hover{
  background-color: var(--Light-green);
  color: var(--White);
}

.loanText {
  font-size: 15px;
}
.loanText1 {
  font-size: 15px;
  margin-left: 20px;
}
.loanText2 {
  font-size: 15px;
  margin-left: 25px;
}

.fetchText{
  font-size: 15px;
  color: rgba(45, 153, 95, 1);
  font-weight: 500;
}

.theamount{
  font-size: 20px;
  font-weight: 700;
  color: rgba(45, 153, 95, 1);
  margin-left: 30px;
}

.monthText{
  font-size: 18px;
  font-weight: 700;
  color:rgba(108, 117, 125, 1);
  margin-left: 65px;
}

.loandgrantcards{
  margin-bottom: 30px;
  margin-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}

.loanContainer{
  margin-top: 15px;
  overflow-x: auto;
}

.loanTable {
 color: black;
 background-color: rgba(233, 236, 239, 1);
 height: 50px;
 font-weight: normal;
 white-space: nowrap;
 /* padding: 30px; */
 text-align: center;
}
/* .loanResponsive {
overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
} */

/* .tableText{
font-size: 15px;
font-weight: normal;

} */
.loanResponsive table th{
  background-color: var(--Light-green);
  color: white;
  /* font-size: 14px; */
}
.loanResponsive table td{
  font-size: 14px;
  /* border: none !important; */
}
.loanResponsive table > td, th{
  /* border: none; */
  /* font-size: 12px; */
}
 .loanResponsive .edit{
    color: var(--Light-green);
    cursor: pointer;
    text-align: center;
 }
 .loanResponsive .accrodBtns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--Light-green);
 }
 .accrodBtns p{
  margin: 0;
  font-size: 18px;
 }
 .loanResponsive .accrodBtns .prev{
  border: 1px solid #2D995F;
  color: var(--Light-green);
  background-color: transparent;
  padding: 5px 20px;
 }
 .loanResponsive .accrodBtns .next{
    padding: 5px 20px;
 }
/* .loanResponsive .edit:hover{
  border: 1px solid var(--Light-green);
  background-color: var(--Light-green);
  color: var(--White);
  border: 10px;
} */

.imgapply img {
  width: 170px;
  height: 165px;
}

.applygrnttxt {
  font-family: 'inter';
  color: #2D995F;
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grntapplytxt {
  color:rgba(108, 117, 125, 1);
  font-weight: 400;
  font-size: 16px;
}

.applyLoan{
  width: 300px;
  height: 55px;
  background-color: #2D995F;
  color: white;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.applyLoan:hover{
  background-color: #46a874;
}

.continueReg{
  color: white;
  font-size: 16px;
  font-weight: 700;
text-align: center;


}

.regContainer{
    display: grid;
    grid-template-columns: 25% 75%;
    /* gap: 30px; */
}
.sideNav{
    background-color: var(--Deep-green);
    padding: 37px 0 0 21px;
    height: 1800px;
    max-width: 339px;
}
.regMenu{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.regMenu a{
    text-decoration: none;
}
/* .regMenu a:active{
    background: linear-gradientrgba((#2D997F) 100%, (#2D997F) 31%);
} */
.regMenu p{
    color: var(--White);
    font-size: 18px;
    font-weight: 400;
    margin:0;
}
.regMenu .active{
    background: linear-gradient9(#2D997F) (#2D997F)0;
}
.formSection{
    padding: 35px 30px;
}
.formSection p{
    font-size: 14px;
}
.formSection h1{
    color: var(--Light-green);
    font-size: 28px;
    font-weight: 700;
}
.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    /* border-radius: 12px; */
   
}
.formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: 700;
}
.mainform{
    width: 100%;
    background-color: var(--White);
    /* border-radius: 12px; */
    margin-top: 26px;
    display: flex;
    flex-direction: column;
}
.actionsBtns button{
  border-radius:0;
  font-size: 14px;
}
.diffbtn {
  width: 145px !important;
  height: 35px;
}

::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    /* --Background:rgba(172, 231, 199, 0.05); */
}
/* .overflow{
    overflow-y: scroll;
    
} */
p{
    margin:0;
}
.logoCont{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid var(--White);
    padding: 21px 64px 16px 23px;
    font-size: 14px;
    color: var(--White);
}
.logoCont p{
    margin-left: 10px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.imgs1{
    height: 100px;
    width: 100%;
    object-fit: contain;
}
.logoCont span{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 89px;
    background-color: #ACE7C7;
    font-size: 16px;
    font-weight: 700;
    color: #227247;
}
.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.sideNavBody{
    /* max-width: 367px; */
}
.regContainer{
    display: flex;
    justify-content: space-between;
    /* grid-template-columns: 25% 75%; */
    /* gap: 30px; */
}
.sideNavContainer{
    background-color: var(--Deep-green);
    height: 1800px;
    position: fixed;
    width: 100%;
    max-width: 305px;   
    overflow-y: auto;
    /* padding: 31px 64px ; */
}
.sideNav{
    padding: 17px 27px 0 24px;
    overflow-y: auto; /* Enable vertical scrolling */
    /* max-height: calc(100vh - 74px); Adjust this value based on your layout */
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}
/* Hide the scrollbar */
.sideNav::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    /* display: none; Hide scrollbar */
}
.sideNav::-webkit-scrollbar-thumb {
    background: var(--Light-green); /* Color of the scrollbar handle */
    border-radius: 4px; /* Rounded corners of the scrollbar handle */
}



.regMenu{
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    margin-top: 40px;
}
.regMenu a{
    text-decoration: none;
    color: var(--White);
}
.regMenu .active {
    background: linear-gradient(to right, #4b9483, #2d998050);
    border-radius: 4px;
}
.regMenu img{
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.regMenu p{
    color: var(--White);
    font-size: 18px;
    font-weight: 400;
    margin:0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formSection{
    width: 100%;
    padding-left: 20rem;
    /* background-color: #4b9483; */
    padding-right: 3rem;
}
.formSectionHeader{
  /* width: 100%; */
  /* padding-top: 40px; */
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSection p{
    font-size: 14px;
}
.formSectionHeader h3{
    color: var(--Light-green);
    font-size: 20px;
    font-weight: 700;
}
.formSectionHeader h4{
    font-weight: 700;
}
.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    border-radius: 12px;
    width: 100%;
}
.analysis{
    padding: 18px 100px 14px 32px;
    background-color: var(--Deep-green);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    /* color: var(--White); */
}
.analysisCont h5,
.analysisCont p{
    color: var(--White);
    margin: 0;
}
.perceCont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    padding-top: 10px;
    /* color: var(--White); */
}
.percent{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    /* color: var(--White); */
}

.dFooter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    margin-top: 1rem;
    width: 100%;
}
.dFooter a {
    text-decoration: none;
}
.logoPic{
    width: 148px;
    height: 33px;
    overflow: hidden;
}
.dFooter button{
    border: 1px solid var(--White);
    border-radius: 8px;
    width: 250px;
    color: var(--White);
    /* font-weight: 600; */
    font-size: 16px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 5px 0 !important;
    gap: 20px;
}



.shadow{
  box-shadow: 5px 5px 10px var(--Gray) ;
}
.formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: 700;
}

.mobileshow{
    display: none;
}
.harmborgers{
    display: none;
}
.harmborgers i{
    font-size: 24px;
}

.accordionCard{
    border: none;
    color: var(--White);
}
.cardHeader{
    background-color: #164B2E;
    color: var(--White);
    border: none;
    padding: 5px 0;
    font-size: 16px !important;
    font-weight: lighter !important;
}
.accordionCard button{
    padding: 10px 10px 0 0 !important;
    width: 100%;
}
.collapses{
    /* background-color: green !important; */
}
.cardBody a{
    /* padding: 20px  0 !important; */
    /* margin: 3rem 0; */
    overflow-y: auto;
    font-size: 14px;
}
/* .manageHover:hover{
    background-color: #bdc3c9;
    width: 100%;
    padding: 40px 40px 40px 40px;
    height: fit-content;
} */

.cardBody{
    background-color: var(--Deep-green);
    color: var(--White);
    padding: 10px 0 10px 23px ;
    border-left: 1px solid var(--White);
    margin-left: 10px;
    overflow-y: auto;
    max-height: calc(100vh - 180px);
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px; */
}
.cardBody::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    display: none;
}
.cardBody::-webkit-scrollbar-thumb {
    background: #2D995F; /* Color of the scrollbar handle */
    border-radius: 4px; /* Rounded corners of the scrollbar handle */
}
.cardBody::-webkit-scrollbar-thumb:hover {
    background: #037538; /* Color of the scrollbar handle on hover */
}

    /* @media(max-width:800px){

        .mobileshow{
            display: block;
        }
        .sideNavBody {
            display: none;
        }
        .webshow{
            display: none;
        }
        .harmborgers{
            margin-right: 20px;
            display: block;
        }
        
        .logoCont{
            width: 100%;
            max-width: 122px;
            
            padding-left: 20px;
            
            margin-bottom: 0;
            border-bottom: 2px solid white;
        }
        .img{
            
            object-fit: contain;
        }
        
        .sideNavBody{
            
        }
        .sideNav{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--White);
            padding:0;
            height: 74px;
            width: 100%;
            
        }
        .regMenu{
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: var(--White);
            
            align-items: left;
            top: 80px;
            box-shadow: 0 -1px 1px rgb(226, 224, 224);
            padding: 27px;
            gap: 30px;
            position: absolute;
            
        }
        .menuOpen{
            display: none;
        }
    
        .regMenu a{
            text-decoration: none;
            padding: 0;
        }
        .regMenu .active {
            background: transparent;
            border-radius: 0;
            color: var(--Light-green);
        }
        .regMenu img{
          width: 20px;
          height: 20px;
          margin-right: 20px;
        }
        .regMenu p{
            color: var(--Black);
            font-size: 16px;
            font-weight: 400;
            margin:0;
            display: flex;
            justify-content: start;
            align-items: center;
        }
        
        .formSection{
            width: 100%;
            padding: 50px 0 0 25px;
        }
        .formSectionHeader{
          width: 100%;
          padding-top: 40px;
          margin-bottom: 10px;
        }
        .formSection p{
            font-size: 12px;
        }
        .formSection h1{
            
            font-size: 18px;
            
        }
        .formSecCont{
            padding: 24px 32px;
            background-color: var(--White);
            border-radius: 12px;
            width: 100%;
        }
        .shadow{
          box-shadow: 5px 5px 10px var(--Gray) ;
        }
        .formSecCont h3{
            color: #343A40;
            font-size: 20px;
            font-weight: 700;
        }
        
        
    } */

    @media (max-width: 900px) {
        .sideNavContainer{
            /* background-color: var(--Deep-green); */
            height: auto;
            position: relative static;
            width: 100%;
            max-width: 100%;   
            overflow-y: unset;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 30px;
            padding-bottom: 20px;
            /* padding: 31px 64px ; */
        }
        
        .logoCont p{
            color: var(--White);
            font-size: 14px;
        }
        .sideNavContainer i{
            font-size: 20px;
            color: var(--White);
        }
        .menuOpen{
            display: none;
        }
        .sideNav{
            /* padding: 17px 27px 0 24px; */
            overflow-y: unset;
            position: absolute;
            overflow-y: unset;
            height: 100%;
            /* max-height: auto; */
            top: 70px;
            left: 0;
            right: 0;
            width: 100%;
            background-color: var(--Deep-green);
            color: white;
            padding: 0;

            /* max-height: calc(100vh - 200px); */
        }
        .logoCont{
            /* width: 100%; */
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: none;
            padding: 21px 0px 16px 20px;
            font-size: 12px;
            gap: 10px;
            color: var(--White);
        }
        .logoCont p{
            margin-left:0;
            /* -webkit-line-clamp: 1;
            display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden; */
        }
        .logoCont span{
            width: 30px;
            height: 30px;
            /* display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 89px;
            background-color: #ACE7C7;
            font-size: 16px;
            font-weight: 700;
            color: #227247; */
        }
        
        .regMenu{
            /* display: flex; */
            /* flex-direction: column; */
            width: 100%;
            margin: 0;
            padding: 30px;
            background-color: var(--Deep-green);
            margin-top: 0;
            border-top: 1px solid white;
        }
        /* .regMenu a{
            text-decoration: none;
            color: var(--White);
        } */
        /* .regMenu .active {
            background: linear-gradient(to right, #4b9483, #2d998050);
            border-radius: 4px;
        }*/

         /* .regMenu img{ 
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }  */

        /* .regMenu p{
            color: var(--White);
            font-size: 18px;
            font-weight: 400;
            margin:0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        } */
        
        
        .dFooter{
            display: none;
        }
        .harmborgers{
            display: block;
        }
    }

@media(max-width:800px){
  .finishedbodyCont{
    padding-left: 0;
    padding-right: 0;
    /* display: list-items; */
  
  }
  .formSecCont{
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
   
  }
  .formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: lighter;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }
  
  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan{
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }
  
  .continueReg{
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
  /* text-align: center; */
  }
}

@media (max-width:992px) {
  .btnStyle {
    display: flex;
    flex-direction: column;
  }

  .actionsBtns {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
  }

  .actionsBtnsspec {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border-radius: 0px;
    padding: 1px;
    margin-top: 5px;
    font-size: 14px;
  }

  .finishedbodyCont {
    padding-top: 110px;
  }

  .btndivStyle {
    display: inline-block; 
    gap: 0px;
  }

  .show {
    margin-top: 10px;
    margin-bottom: 10px;
  }

}
  
  
