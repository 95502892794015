*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
}
.fetchedTxt{
  font-size: 16px;
  font-weight: 500;
}

.analysis{
  padding: 18px 100px 14px 32px;
  background-color: var(--Deep-green);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  /* color: var(--White); */
}
.analysisCont h5,
.analysisCont p{
  color: var(--White);
  margin: 0;
}

.perceCont{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding-top: 10px;
  /* color: var(--White); */
}

.btnStyle {
  padding: 20px 0 0 0 ;
  margin-bottom: 20px;
}

.btndivStyle {
  display: flex; 
  gap: 20px;
}

.percent{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  /* color: var(--White); */
}

.finishedbodyCont{
  width: 100%;
  padding: 0 7rem 0 7rem;
  /* padding-right: 4rem; */


}
.mainTable table td{
  /* padding: 15px 0 0 5px; */
align-items: center;
padding: 2px  3px 3px 20px;
padding-left: 10px;

  /* display: none; */
}
.productText1{
  font-weight: 700;
  margin-top: 5rem;
}


.endded {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
}
.topPadding{
  width: 100%;
  padding-top: 1rem;
}
.formSecCont{
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSecCont h3{
  color: #343A40;
  font-size: 20px;
  font-weight: 700;
}

.shadow {
  box-shadow: 0 0 5px rgb(238, 236, 236);
}

.applycntnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.loandethead{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 30px; */
}
.loandethead .formIntBtn{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  gap: 10px;
}

.formIntBtn .btn1{
  background-color: var(--White);
  border: 1px solid rgb(187, 183, 183) !important;
  color: var(--Black);
  font-size: 14px;
  font-weight: 600;
}
.formIntBtn .btn1:hover{
  border: 2px solid var(--Light-green) !important;
  color: var(--Light-green);
  background-color: transparent;
}
.formIntBtn .btn2{
  font-size: 14px;
}

/* .loandethead button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: var(--Light-green);
  font-size: 16px;
  gap: 5px;
} */
.loandethead button:hover{
  background-color: var(--Light-green);
  color: var(--White);
}

.loanText {
  font-size: 15px;
}
.loanText1 {
  font-size: 15px;
  margin-left: 20px;
}
.loanText2 {
  font-size: 15px;
  margin-left: 25px;
}

.fetchText{
  font-size: 15px;
  color: rgba(45, 153, 95, 1);
  font-weight: 500;
}

.theamount{
  font-size: 20px;
  font-weight: 700;
  color: rgba(45, 153, 95, 1);
  margin-left: 30px;
}

.monthText{
  font-size: 18px;
  font-weight: 700;
  color:rgba(108, 117, 125, 1);
  margin-left: 65px;
}

.loandgrantcards{
  margin-bottom: 30px;
  margin-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}

.loanContainer{
  margin-top: 15px;
  overflow-x: auto;
}

.loanTable {
 color: black;
 background-color: rgba(233, 236, 239, 1);
 height: 50px;
 font-weight: normal;
 white-space: nowrap;
 /* padding: 30px; */
 text-align: center;
}
/* .loanResponsive {
overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
} */

/* .tableText{
font-size: 15px;
font-weight: normal;

} */
.loanResponsive table th{
  background-color: var(--Light-green);
  color: white;
  /* font-size: 14px; */
}
.loanResponsive table td{
  font-size: 14px;
  border: none !important;
}
.loanResponsive table > td, th{
  /* border: none; */
  /* font-size: 12px; */
}
 .loanResponsive .edit{
    color: var(--Light-green);
    cursor: pointer;
    text-align: center;
 }
 .loanResponsive .accrodBtns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--Light-green);
 }
 .accrodBtns p{
  margin: 0;
  font-size: 18px;
 }
 .loanResponsive .accrodBtns .prev{
  border: 1px solid #2D995F;
  color: var(--Light-green);
  background-color: transparent;
  padding: 5px 20px;
 }
 .loanResponsive .accrodBtns .next{
    padding: 5px 20px;
 }
/* .loanResponsive .edit:hover{
  border: 1px solid var(--Light-green);
  background-color: var(--Light-green);
  color: var(--White);
  border: 10px;
} */

.imgapply img {
  width: 170px;
  height: 165px;
}

.applygrnttxt {
  font-family: 'inter';
  color: #2D995F;
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grntapplytxt {
  color:rgba(108, 117, 125, 1);
  font-weight: 400;
  font-size: 16px;
}

.applyLoan{
  width: 300px;
  height: 55px;
  background-color: #2D995F;
  color: white;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.applyLoan:hover{
  background-color: #46a874;
}

.continueReg{
  color: white;
  font-size: 16px;
  font-weight: 700;
text-align: center;


}

.regContainer{
    display: grid;
    grid-template-columns: 25% 75%;
    /* gap: 30px; */
}
.sideNav{
    background-color: var(--Deep-green);
    padding: 37px 0 0 21px;
    height: 1800px;
    max-width: 339px;
}
.regMenu{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.regMenu a{
    text-decoration: none;
}
/* .regMenu a:active{
    background: linear-gradientrgba((#2D997F) 100%, (#2D997F) 31%);
} */
.regMenu p{
    color: var(--White);
    font-size: 18px;
    font-weight: 400;
    margin:0;
}
.regMenu .active{
    background: linear-gradient9(#2D997F) (#2D997F)0;
}
.formSection{
    padding: 35px 30px;
}
.formSection p{
    font-size: 14px;
}
.formSection h1{
    color: var(--Light-green);
    font-size: 28px;
    font-weight: 700;
}
.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    /* border-radius: 12px; */
   
}
.formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: 700;
}
.mainform{
    width: 100%;
    background-color: var(--White);
    /* border-radius: 12px; */
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}
.actionsBtns button{
  border-radius:0;
  font-size: 14px;
}
.diffbtn {
  width: 145px !important;
  height: 35px;
}

@media(max-width:800px){
  .finishedbodyCont{
    padding-left: 0;
    padding-right: 0;
  
  }
  .formSecCont{
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
   
  }
  .formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: lighter;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }
  
  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan{
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }
  
  .continueReg{
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
  /* text-align: center; */
  }
}


  

@media (max-width:992px) {
    

    .btnStyle {
        display: flex;
        flex-direction: column;
      }
    
      .actionsBtns {
        width: 100%;
        display: inline-block;
        margin-bottom: 20px;
      }
    
      .finishedbodyCont {
        padding-top: 110px;
      }
    
      .btndivStyle {
        display: inline-block; 
        gap: 0px;
      }
    
      .show {
        margin-top: 15px;
        margin-bottom: 10px;
      }

      .formobile {
        margin-bottom: 10px;
        /* align-items: center; */
      }

}


.btndivStyle {
  display: flex; 
  gap: 20px;
}

.btnStyle {
  padding: 20px 0 0 0 ;
  margin-bottom: 20px;
}

.finishedbodyCont{
  width: 100%;
  padding: 0 7rem 0 7rem;
  /* padding-right: 4rem; */
}

@media(max-width:800px){
  .finishedbodyCont{
    padding-left: 0;
    padding-right: 0;
  
  }
  .formSecCont{
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
   
  }
  .formSecCont h3{
    color: #343A40;
    font-size: 20px;
    font-weight: lighter;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }
  
  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan{
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }
  
  .continueReg{
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
  /* text-align: center; */
  }

  .btnStyle {
    display: flex;
    flex-direction: column;
  }

  .actionsBtns {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
  }

  .finishedbodyCont {
    padding-top: 110px;
  }

  .btndivStyle {
    display: inline-block; 
    gap: 0px;
  }

  .show {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .newWidth{
    padding: 0;
  }
}


/*  */
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
}

:root{
    --gray:#6C757D;
    --black:#000000;
    --green:#2D995F;
    --red:#FF1708;
    --light-black:#343A40;
    --light-gray:#E9ECEF;
    --white:#ffffff;
    --gray-light:#ADB5BD;
}

.dashboardDiv{
    padding:34px;
}

.headerDash{
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
}

.headerO .welcome{
    font-size: 12px;
    font-weight: 400;
    color:var(--gray);
    margin: 0;
}

.headerO .welName{
    font-size: 20px;
    font-weight: 700;
    color:#000000;
    margin: 0;
}

.headerT{
    display: flex;
    align-items: center;
    gap:24px;
}

.alignF{
    text-align: end;
}

.alignF .portalSmall{
    font-size: 12px;
    font-weight: 400;
    color:var(--gray)
}

.alignF .compName{
    font-size: 20px;
    font-weight: 700;
    color:var(--black)
}

.portalClass{
    width:62px;
    height: 62px;
}

.notifyClass{
    width:24px;
    height:24px;
    position: relative;
}

.notifyNumber{
    position: absolute;
    top:0;
    right:0;
    margin-right:-10px;
    margin-top: -10px;
    background-color: var(--red);
    border-radius: 50%;
    width:15px;
    height:15px;
    padding:2px;
    color:var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
   
}

.imagesStyle{
    width:100%;
    height: 100%;
    object-fit: cover;
}

/* NavBar*/
.setUp{
    display:flex;
    align-items: center;
    gap:1px;
    font-size: 14px;
    font-weight: 600;
    color:var(--light-black)
}
.navbar {
    display: flex;
    background-color: #f8f9fa;
    padding: 10px 20px;
    padding-right: 150px;
    padding-left: 30px;
    align-items: center;
    gap:28px;
    margin-left: -30px;
    margin-right: -30px;
}


.navbar .dropdown {
    position: relative;
    display: inline-block;
}
.navbar .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    min-width: 160px;
}
.navbar .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.navbar .dropdown-content a:hover {
    background-color: #f1f1f1;
}
.navbar .dropdown:hover .dropdown-content {
    display: block;
}
.navbar .active {
    color: green;
}

.dashGrid{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 25px;
}

.receiveablesDiv{
    border: 1px solid var(--green);
    border-radius: 4px;
    padding:21px 23px;
}

.totalRecv{
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    margin-bottom: 40px;
}

.unpaidRecvv{
    margin-bottom: 6px;
}

.amountRecv{
    font-size: 36px;
    font-weight: 700;
    color:var(--light-black);
    margin-bottom: 0;
}

.userdiv{
    display: flex;
    gap:12px;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 25px;
}

.dashD{
    margin-left: 52px;
}

.daH{
    font-size: 20px;
    font-weight: 700;
    color: var(--light-black);
    margin-bottom: 26px;
    margin-top: 26px;
}

.adminP{
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    background-color: var(--light-gray);
    padding:9px 21px;
    border-radius: 4px;
    margin-bottom: 12px;
}

.userStyle{
    width: 39px;
    height: 39px;
}

.manageUser{
    font-size: 16px;
    font-weight: 500;
    color:var(--green)
}

.cru{
    font-size: 10px;
    font-weight: 300;
    color: var(--gray);
}

.divSy{
    background-color: var(--light-gray);
    height: 21px;
    border-radius: 20px;
    margin-top: 15px;
}

.adminSetting{
    border: 1px solid var(--green);
    border-radius: 4px;
}

.transcation{
    display: flex;
    gap:38px;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 14px;
    border-bottom: 5px solid var(--light-gray);
}

.laondetheadTopCards{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    margin-top: 1rem;
    padding-left: 30px;
  }
  .laondetheadTopCards .card{
    border: 1px solid var(--Light-green);
    padding: 24px 60px 30px 27px;
    text-align: left;
  
  }
  .laondetheadTopCards .card h4{
    color: var(--Light-green);
    font-weight: 800;
    margin-top: 2px;
    /* font-size:20px; */
  }
  .laondetheadTopCards .card p{
    margin-bottom:0;
  }

.btnBtn{
    display:flex;
    gap:16px;
    margin-bottom: 20px;
}
.btnDash{
    border:1px solid var(--green);
    padding: 12px 10px;
    border-radius: 4px;
    background-color: var(--white);
    color:var(--green);
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    cursor: pointer;
}



.tableDiv{
    width:100%;
    border-collapse: collapse;
    margin-top: 21px;
    align-items: center;
    display: table;
}

.table thead{
    background-color: var(--light-black) !important;
    color:var(--white) !important;
    align-items: center !important;
}

.thHead{
    align-items: center;
    width:100%
}

.tdHead{
    font-size: 16px;
    font-weight: 700;
    padding:5px 5px;
    align-items: center;
}



.datebtn{
    background-color: var(--white);
    border-radius: 4px;
    padding: 5% 2% 5% 1%;
    border:1px solid var(--gray-light);
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    text-align: left;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: left;
    outline: 0;
    padding-left: 5px;
}

.rrrbtn{
    background-color: var(--white);
    border-radius: 4px;
    padding: 5% 0% 5% 1%;
    border:1px solid var(--gray-light);
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    text-align: left;
    margin-top: 5px;
    outline: 0;
}

.dsnbtn{
    background-color: var(--white);
    border-radius: 4px;
    padding: 5% 2% 5% 1%;
    border:1px solid var(--gray-light);
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    text-align: left;
   
    margin-top: 5px;
    outline:0;
}

.typebtn{
    background-color: var(--white);
    border-radius: 4px;
    padding: 5% 2% 5% 1%;
    border:1px solid var(--gray-light);
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    text-align: left;
    margin-top: 5px; 
    outline: 0;
}

.selectionbtn{
    text-wrap: nowrap;
    background-color: var(--white);
    border-radius: 4px;
    padding: 5% 2% 5% 1%;
    border:1px solid var(--gray-light);
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    text-align: left;
    margin-top: 5px;
    outline:0;
}

.referencebtn{
    text-wrap: nowrap;
    background-color: var(--white);
    border-radius: 4px;
    padding: 5% 2% 5% 1%;
    border:1px solid var(--gray-light);
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    text-align: left;
    margin-top: 5px;
    outline:0;
}

.vatbtn{
    text-wrap: nowrap;
    background-color: var(--white);
    border-radius: 4px;
    padding: 5% 2% 5% 1%;
    border:1px solid var(--gray-light);
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    text-align: left;
    margin-top: 5px;
    outline:0;
}

.amountbtn{
    text-wrap: nowrap;
    background-color: var(--white);
    border-radius: 4px;
    padding: 5% 2% 5% 1%;
    border:1px solid var(--gray-light);
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    /* margin-left: 7px; */
    text-align: right;
    margin-top: 5px;
    outline:0;
    width: 100%;
}

.amountrec{
    text-wrap: nowrap;
    background-color: var(--white);
    border-radius: 4px;
    padding: 5% 2% 5% 1%;
    border:1px solid var(--gray-light);
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    /* margin-left: 7px; */
    text-align: right;
    margin-top: 5px;
    outline:0;
}

.actions{
    display:flex;
    gap:10px;
    /* padding: 5% 2% 5% 1%; */
    border:0;
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    margin-left: 7px;
    text-align: right;
    margin-top: 5px;
    background:none;
}

.amountrecv4{
    background:none;
    border:0;
    margin-left: 10px;
}

.down{
    margin-right: 10px;
}

.inputBox{
    background-color: var(--white);
    /* margin-left: 20px; */
}

.btnDel{
    /* padding: 7% 40% 8% 20%; */
    border:0;
    font-size: 14px;
    font-weight: 400;
    color:var(--light-black);
    /* margin-left: 7px; */
    text-align: center;
    margin-top: 5px;
    background:none;
}

.tdBody{
    width:10%;

}
.tdBody input{
    width:100%;
}
.tableBody .trBody:nth-child(odd) {
    background-color: var(--white);
}

.tableBody .trBody:nth-child(even) {
    background-color: var(--light-gray);
}

.btnBtns{
    display:flex;
    gap:20px;
    margin-top: 44px;
    justify-content: center;
}

.saveBtn{
    padding:12px 20px 12px 20px;
    background-color: var(--green);
    border:0;
    border-radius: 4px;
    color:var(--white);
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.selectwBtn{
    border:0;
    border:1px solid var(--green);
    padding:12px 20px 12px 20px;
    background-color: var(--white);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color:var(--green);
    cursor: pointer;
}

.reviewBtn{
    border:0;
    border:1px solid var(--green);
    padding:12px 20px 12px 20px;
    background-color: var(--white);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color:var(--green);
    cursor: pointer;
}


.btnDash.active {
    background-color: #2D995F;
    color: white;
}
/*  */