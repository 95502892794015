.a4size{
    background-color: grey;
}
.wholeCtDiv{
    background-color: white;
    width: 21cm;
    height: 29.7cm;
    margin: auto;
    padding: 1rem 2rem 0 2rem;
    padding-top:6rem ;
    
}

.truncate {
   
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
}

.truncatsade {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: clip;
    text-align: right;
    margin: 0;
    padding: 0;
}

.flexTopContnt{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}
.numberParty{
    /* margin-bottom: 15px; */
}
.numberParty h4{
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 8px;
}
.tinyflexTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 55px;
    width: 100%;
    /* margin-top: 10px; */
    margin: 0;
}

.tinyflexTodafp{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 55px;
    width: 70%;
    /* margin-top: 10px; */
    margin: 0;
    font-size: 11px;
}

.tinyflexTop p{
    margin: 0;
    font-size: 11px;
}

.imgDivFlex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 40px;
}
.imgDivFlex img{
    width: 30px;
    height: 30px;
}
.imgDivFlex h2{
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    color: grey;
}

.middleCnts{
    width: 50%;
}

.fromCntpart{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-top: 30px; */
}
.fromCntpart h6{
    font-size: 12px;
    font-weight: 550;
    color: grey;
    margin: 0;
}
.fromCntpart h2{
    font-size: 16px;
    font-weight: 700;
    color: black;
    margin: 0;
}
.VatNoParty{
    /* margin-top: 10px; */
}
.VatNoPartyFlex{
    display: flex;
    /* width: 45%; */
    /* align-items: center; */
    justify-content: space-between;
    gap: 20px;
    /* align-items: center; */
}
.VatNoParty h6{
    font-size: 12px;
    font-weight: 550;
    margin: 0;
    margin-bottom: 3px;
    color: rgb(19, 7, 7);
    margin-top: 10px;
}
.PostalParty{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%
    /* align-items: center; */
    
}
.PostalParty h6{
    font-size: 12px;
    font-weight: 550;
    margin: 0;
    color: rgb(58, 58, 58);
}
.PostalParty p{
    font-size: 10px;
    /* font-weight: 550; */
    margin: 0;
    color: rgb(58, 58, 58);
}
.ToFromFlex{
    display: flex;
    width: 100%;
    /* align-items: center; */
    gap: 30px;
    /* width: 90%; */
}
.thTable{
    width: 100%;
    margin-top: 10px;
    font-weight: 100!important;
}
.thTable thead{
    color: grey;
    font-style: italic;
    background-color: rgb(218, 218, 218);
    border-top: 2px solid grey;
    border-bottom: 2px solid grey;
    font-size: 10px;
    font-weight: lighter;
    /* padding: 20px 0; */
}
.thTable th{
    padding: 3px 0;
}
.thTable td{
    padding: 8px 0;
     color: black;
     /* font-weight: ; */
}
.thTable tbody{
    color: black;
    font-style: italic;
    font-size: 10px;
    font-weight: 530;
    /* padding: 0 90px; */
    border-bottom: 1px solid rgb(184, 184, 184);
}
.boldtable{
    font-weight: bold;
    text-align: right;
}
.tablebodyCnt{
border-bottom: 1px solid rgb(184, 184, 184);
/* padding:  90px 0!important; */
}

.tablebodyCntBottom{
    /* margin-top: 200px; */
}
.footertypeParty{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    text-align: left;
    font-size: 10px;
    margin-bottom: 10px!important;
   border-top: 2px solid rgb(189, 187, 187);
   margin-top: 5rem;
   padding-top: 15px;
}
.footerfirstdiv{
    display: flex;
    flex-direction: column;
}
.footerfirstdiv p{
    margin: 0;
}

.spantxt{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.spantxt1{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}
.balanceTxt{
    font-size: 16px;
    color: rgb(221, 217, 217);
    font-weight: 650;
    text-align: right;
}
.balanceTxtNum{
    font-size: 17px;
    color: black;
    font-weight: 700;
    text-align: right;
}

.address{
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
}

.footedDiv{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.printCheque{
    display: flex;
    justify-content: flex-end;
    background-color: #fff !important;
    padding-right: 15% !important; 

}
@media print{
    .printCheque{
        display: none;
    }
}