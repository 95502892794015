.redeemTops {
    font-family: Arial, sans-serif;
    margin: 0;
    background-color: #f0f0f0;
  }
  
  .report {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    /* border: 1px solid #000; */
    padding: 20px;
    position: relative;
    overflow: hidden;
  }
  
  /* .report::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
   
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.1;
    z-index: 0;
  } */
 .titleTop{
    font-size: 20px;
    font-weight: 700;
    color: #000;
    text-align: center;
 } 

 .subTitle{
    font-size: 20px;
    margin-top: 10px;
    color:#000;
    text-align: center;
 }
.openTop{
  padding-bottom: 20px;
}
 .region{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 5px;
 }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }
  
  .title {
    flex-grow: 1;
  }
  
  /* h1, h2 {
    color: #006400;
    margin: 0;
  }
  
  h1 {
    font-size: 18px;
  }
  
  h2 {
    font-size: 14px;
  } */
  
  .details {
    /* border-top: 1px solid #000; */
    border-bottom: 3px solid #000;
    padding: 10px 0;
    margin-bottom: 20px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    /* margin: 5px 0; */
  }
  .rowB {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 5px 0; */
     border-top: 3px solid #000;
  }
  
  .tableData {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .td {
    padding: 5px;
    border: none;
  }
  
  .amount {
    text-align: right;
    font-weight: 700;
    font-size: 18px;
  }
  .amountC {
    text-align: right;
    font-weight: 700;
    padding-bottom: 30px;
    font-size: 18px;
  }
  
  .total {
    font-weight: bold;
    text-align: right;
  }
  .totalC {
    font-weight: bold;
    text-align: right;
    padding-bottom: 30px;
    font-size: 22px;
  }
  
  .footer {
    margin-top: 20px;
  }
  
  .footer .row {
    margin: 10px 0;
  }

  .ledgerBal{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 30px;
  }

  .ledgerAmount{
    font-size: 22px;
    font-weight: 700;
    text-align: right;
    padding-bottom: 30px;
  }
  .ledgerAmount2{
    font-size: 18px;
    font-weight: bold;
    text-align: right;
  }
  .ledgerAmount{
    font-size: 18px;
    font-weight: 700;
    text-align: right;
    padding-bottom: 30px;
  }

  .addT{
    font-size: 18px;
    font-weight: bold;
  }
  .addStatment{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 30px;
  }

  .ledgerOt{
    font-size: 16px;
    font-weight: 500;

  }

  .ledgerOtC{
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 30px;
  }

  .statmentBal{
    font-weight: 500;
  }

  .rowFooter{
    display:flex;
    justify-content: space-between;
  }

  .redeemTops {
    font-family: Arial, sans-serif;
    margin: 0;
    background-color: #f0f0f0;
  }
  
  .report {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    /* border: 1px solid #000; */
    padding: 20px;
    position: relative;
    overflow: hidden;
  }
  

 .regions{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 25px;
    margin-top: 5px;
 }
 
 
  .detailsB {
    /* border-top: 1px solid #000; */
   
    padding: 10px 0;
    margin-bottom: 20px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    /* margin: 5px 0; */
  }
 
  .total {
    font-weight: bold;
    text-align: right;
  }

  .tableR2{
    width:100%;
    border-collapse: collapse;
  }
  .twoTD{
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    text-align: left;
    font-weight: 500;
  }
  .mainTable table td {
    padding: 0 0 0 5px;
    align-items: center;
  border: none
    /* display: none; */
  }

  .mainTable th{
    border: none;
  }
  .newTD{
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    text-align: left;
    font-weight: 500;
    width: 500px;
  }

 .tableSubHead{
    text-align: left;
    font-weight: bold;
    font-size: 15px;
   
 }
 .tableSub2Head{
    text-align: right;
    font-weight: bold;
    font-size: 15px;
 }
  .twoTDAmount{
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    text-align: right;
  }
  @media print {
    /* Force a new page before each section */
    .pageBreak {
    
      page-break-before: always;
    }
  
    /* Alternatively, you can use page-break-after */
    .page-break-after {
      page-break-after: always;
    }
  }