/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: #6C757D;
  }
  
  
  .background {
      background-image: url('../../assets/promix/Login.svg');
      background-size: cover; /* Cover the entire container */
      width: 100%;
      background-position:center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      height: 100vh; /* 100% of the viewport height */
      overflow: hidden;
      display: flex;
      justify-content: right;
      align-items: center;
      padding-right: 7rem;
  
    }
  
    .otpGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .otpInputs {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
    
    .otpInput {
      width: 57px;
      height: 69px;
      text-align: center;
      font-size: 20px;
      border: 1px solid #e4e4e4;
    }
  
    .mainContainerx {
      background-color: rgba(0, 0, 0, 0.8);
      width: 100%;
      max-width: 500px;
      height: 523px;
      border-radius: 20px;
      
  
      display: flex;
      justify-content: center;
      align-items: center;
    
    }
    .thecntnt{
      display: flex;
      flex-direction: column;
      color: var(--White);
      gap: 10px;
      width: 100%;
      max-width: 90%;
    }
    .thecntnt form{
      display: flex;
      flex-direction: column;
      color: var(--White);
      gap: 20px;
      width: 100%;
     
    }
  
    .head{
      text-align: center;
    }
    .thecntnt p{
      color: var(--White);
    }
  
    .SignUptxt {
      font-family: 'inter';
      font-weight: 800;
      font-size: 40px;
      margin-bottom: 10px;
      margin-top: 20px;
    }
    .SignUptxt1 {
      font-family: 'inter';
      font-weight: 800;
      font-size: 40px;
      margin-bottom: 10px;
      /* margin-top: 20px; */
    }
  
    .SignUptxtsub {
      font-family: 'inter';
      font-weight: 400;
      font-size: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      padding-bottom: 15px;
    }
    .SignUptxtsub1 {
      font-family: 'inter';
      font-weight: 400;
      font-size: 14px;
      display: flex;
      justify-content: center;
      text-align: center;
      padding-bottom: 15px;
    }
  
    .snupbtn {
      background-color: #3770ab;
      color: white;
      width: 100%;
      margin-top: 32px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'inter';
      padding: 12px;
      border: none !important;
    }
    .backgroundMobile{
      display: none;
    }
  
    .lgin {
      font-weight: 400;
      font-size: 14px;
      text-align: center;

    }
  
    .lgin span {
      color: #3770ab;
    }
    .mainContainerMobile{
      display: none;
    }

    @media(max-width: 950px) {
      .otpInputs {
        display: flex;
        justify-content: center;
        gap: 10px;
      }
      .otpInput {
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 20px;
        border: 1px solid #e4e4e4;
      }
      .mainContainerMobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        max-width: 900px;
        height: 90vh;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 10px; /* optional, for rounded corners */
        margin: auto; /* centers the container in its parent */
      }
      .mainContainerx{
        /* display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 500px;
        height: 100%;
   
        border-radius: 0 !important; */
        display: none;
      }
      .thecntnt{
       
        width: 100%;
        max-width: 90%;
        padding: 20px;
      }
      .SignUptxt {
       
        font-weight: 800;
        font-size: 25px;
        margin-bottom: 10px;
        margin-top: 20px;
        
      }
      .SignUptxtsub {
        font-weight: 400;
        font-size: 15px;
        display: flex;
        margin-top: -10px;
        justify-content: center;
        text-align: center;
        padding-bottom: 15px;
      }
      .background{
        display: none;
      }
      .SignUptxt1 {
       
        font-weight: 800;
        font-size: 25px;
        margin-bottom: 10px;
        /* margin-top: 20px; */
      }
      .SignUptxtsub1 {
      
        font-weight: 400;
        font-size: 14px;
        display: flex;
        justify-content: center;
        text-align: center;
        padding-bottom: 15px;
      }
      .backgroundMobile {
        justify-content: center;
        align-content: center;
        background-image: url('../../assets/promix/Login.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100vh;
        width: 100vw;
        display: flex;
      }
    }