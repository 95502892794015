*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* background-color: #f0f0f0; */
}

.a4 {
    width: 29.7cm;
    min-height: 21cm;
    margin: 0 auto; /* Set margin-top to 0 */
    padding: 1cm;
    background-color: white;
    padding: 0.5in;
    page-break-after: always; 
}

.headerTop {
  font-size: 20px;
  text-align: left;
  /* padding-top: 20px; */
  margin-bottom: 0;
  font-weight: 600;
}

.headerTwo {
  /* font-weight: 800; */
  text-transform: uppercase;
  text-wrap: wrap;
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
}

.tableDivF {
  display: table;
  border-collapse: collapse;
  margin-top: 30px;
  /* border: 1px solid gray; */
  border-bottom: 0;
  /* font-size: 11px; */
}

.tableBodyFirst {
  border: 1px solid #eceaea;
}

.tableBodyFirst > .tableBodyTR {
  border: 1px solid #eceaea;
}
.tableBodyFirst > .tableBodyTR > td {
  border: 1px solid #eceaea;
  padding: 2px;
}

.text {
  background-color: #757373;
}
