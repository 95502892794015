.mobileMenu {
  display: none;
}
@media (max-width: 950px) {
  .mobileMenu {
    display: block;
    position: absolute;
    top: 0;
    background-color: rgb(0, 0, 0, 0.75);
    left: 0;
    z-index: 999999999999;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row-reverse;
    overflow: scroll;
  }

  .mobileBgr {
    display: block;
    background-color: white;
    width: 80%;
    height: 100vh;
    justify-content: flex-end;
    overflow: scroll;
  }

  .closeMenu {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    padding-top: 20px;
    padding-right: 20px;
    cursor: pointer;
  }

  .closeMenu button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .menuMobile {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
    padding: 20px 30px;
    list-style-type: none;
    margin: 0;
  }

  .menuItem {
    width: 100%;
    margin-bottom: 10px;
  }

  .menuItem a {
    text-decoration: none;
    color: #333;
    display: block;
    padding: 10px 0;
    font-size: 16px;
  }

  .dropdownButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    background: none;
    border: none;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    text-align: left;
  }

  .dropdownContent {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-top: 5px;
  }

  .dropdownItem {
    text-decoration: none;
    color: #555;
    padding: 8px 0;
    display: block;
    font-size: 14px;
  }

  .dropdownItem:hover {
    color: #007bff;
  }
}