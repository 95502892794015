*,
::after,
::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.generalBody{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #fff;
}
.mainContainer{
    width: 90%;
    height: 100%;
    background-color: ghostwhite;
    padding: 10px 20px;
    position: relative;
}
.bckground{
    position: absolute;
    left: 30%;
    top: 10%;
}
.bckground img{
    opacity: 0.05;
    width: 400px;
    height: 400px;
}
.mainHeader{
    /* display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center; */
    text-align: center;
}
.mainHeader h1{
    font-weight: 900;
    font-size: 30px;
    color: #091261;
    text-transform: uppercase;
}
.mainHeader h3{
    font-weight: 500;
    font-size: 20px;
    color: #000;
    text-transform: uppercase;
}
.mainHeader h4{
    font-weight: 500;
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
}
.section1, 
.section2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.section1{
    margin: 0 0.5rem;
    margin-top: 3rem;
}
.section2{
    margin: 0 0.5rem;
}

.flexData{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flexData h5, h4{
    font-size: 18px;
}
.flexData p{
    font-size: 18px;
    font-weight: 700;
    color: black;
}
.amountInWord p{
    font-size: 18px;
    font-weight: 700;
    color: black;
}
.fullWith p{
    font-size: 18px;
    font-weight: 700;
    color: black;
}
.section6 p{
    font-size: 18px;
    font-weight: 700;
    color: black;
}
.border{
    border-bottom: 2px solid black;
    text-align: center;
}
.amountBorder{
    border: 2px solid black;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.amountInWord{
    width: 100%;
    border-bottom: 2px solid black;
    text-align: center;
}
.section5{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media print {
    .generalBody {
        width: 100%;
        height: 100%;
        background-color: rgb(214, 213, 213);
    }
    .flexData h4{
        white-space: nowrap;
    }

    .mainContainer {
        width: 100%;
        height: 100%;
        /* background-color: ghostwhite; */
        padding: 10px 20px;
        /* position: relative; */
    }
}