.tab-selector {
    margin-right: 2rem;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 2px solid transparent;
    font-weight: 500; /* medium */
    font-size: 0.875rem; /* text-sm */
    line-height: 1.25rem; /* leading-5 */
    cursor: pointer;
    white-space: nowrap;
  }
  
  .tab-selector.active {
    border-bottom-color: #5a67d8; /* indigo-500 */
    color: #5a67d8; /* indigo-600 */
  }
  
  .tab-selector.active:focus {
    outline: none;
    color: #434190; /* indigo-800 */
    border-bottom-color: #4c51bf; /* indigo-700 */
  }
  
  .tab-selector.inactive {
    border-bottom-color: transparent;
    color: #a0aec0; /* gray-500 */
  }
  
  .tab-selector.inactive:hover {
    color: #718096; /* gray-600 */
    border-bottom-color: #e2e8f0; /* gray-300 */
  }
  
  .tab-selector:focus {
    outline: none;
    color: #718096; /* gray-600 */
    border-bottom-color: #e2e8f0; /* gray-300 */
  }
  