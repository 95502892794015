
*,
::after,
::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.mainBodyss{
    padding: 0 25rem;
    /* width: 800px; */
    /* display: none; */
}
.container{
    /* display: block; */
    background-color: ghostwhite;
    /* align-items: center; */
    /* justify-content: center; */
    padding: 0;
    text-align: center;
    overflow-x: hidden;
    max-width: 70%;
}
.bckgroundicon{
    position: relative;
    opacity: 0.1;
    

}
/* .img{
    width: 500px;
    height: 500px;
} */
.thediv{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
/* .bckgroundicon{
    background-image: url('https://th.bing.com/th/id/R.d1a09df98c52144086459a9a451ba97c?rik=iN7mo4kpq8DDNg&pid=ImgRaw&r=0');
    /* width: 100%; */
    /* background-size: 30%;
    background-repeat: no-repeat;
    background-position: center!important;
    border-radius: 50%!important; */
    /* position: relative; */
    /* background: rgba(255, 255, 255, 0.3); */
    /* backdrop-filter: blur(10px); */
    /* opacity: 0; */

.dLabel{
    font-size: 15px;
    font-weight: 700;
}
.inputdata{
    display: flex;
    justify-content: space-between;
    text-align: center;
    gap: 10px;
}
.inputdata p{
    border-bottom: 2px solid black;

}

.styledInp{
    border: none;
}







.heads{
    padding: 0;
    margin: 0;
    
}
.mainBodyss h1{
    padding: 0;
    margin: 0;
}
.mainBodyss h2{
    padding: 0;
    margin: 0;
}
.mainBodyss h3{
    padding: 0;
    margin: 0;
}
.mainBodyss input[type="date"]{
    padding: 15px 0;
    font-size:17px;
    text-align: center;
    border: none;
    background: transparent;
    border-bottom: 2px solid black;
    border-radius: 0;

}

.mainBodyss input[type="number2"]{
    /* padding: 13px 0; */
    font-size:17px;
    text-align: center;
    border: 2px solid black;
    background: transparent;
    /* border-bottom: 2px solid black; */
    border-radius: 0;

}
.mainBodyss input[type="number1"]{
    padding: 15px 0;
    font-size:17px;
    text-align: center;
    border: 0;
    background: transparent;
    border-bottom: 2px solid black;
    border-radius: 0;

}
.mainBodyss h3{
    color: black;
}

.mainBodyss input[type="text4"]{
    padding: 17px 15px;
    font-size:17px;
    text-align: center;
    border: none;
    background: transparent;
    border-bottom: 2px solid black;
    border-radius: 0;
    width: auto;
}
.mainBodyss input[type="text1"]{
    padding: 15px 0;
    font-size:17px;
    text-align: left;
    border: none;
    background: transparent;
    border-bottom: 2px solid black;
    border-radius: 0;
    width: 100%;
}
.mainBodyss input[type="text2"]{
    padding: 15px 0;
    font-size:17px;
    width: 15%;
    text-align: center;
    border: none;
    background: transparent;
    border-bottom: 2px solid black;
    border-radius: 0;
}
.mainBodyss input[type="text3"]{
    padding: px 0;
    font-size:17px;
    width: 15%;
    text-align: center;
    border: none;
    background: transparent;
    border-bottom: 2px solid black;
    border-radius: 0;
}
/* .inputD{
    margin-left: 0;
    padding-left: 0;
    text-align: left;
/* } */ 
.inputF{
    margin-left: 0;
    padding-left: 0;
    text-align: left;
    border: 0;
    background: transparent;
    /* border-bottom: 2px solid black; */
    border-radius: 0;
}

.mainBodyss input[type="text2"]{
    padding: 15px 0;
    font-size:17px;
    width: 30%;
    text-align: center;
    border: none;
    background: transparent;
    border-bottom: 2px solid black;
    border-radius: 0;
}

.mainBodyss input[type="number5"]{
    padding-top: 15px;
    padding-bottom: 15px;
    font-size:17px;
    text-align: center!important;
    border: none;
    background: transparent;
    border-bottom: 2px solid black;
    border-radius: 0;
    /* padding-right: 50%; */
    width: 100%;

}
.inputE{
    margin-right: 0;
    padding-right: 0;
    text-align: right;
    border: none;
    background: transparent;
    /* border-bottom: 2px solid black; */
    border-radius: 0;
}
.firstrow{
    display: flex;
    justify-content: space-around;
    
    gap: 300px;
    /* padding-left: 40px;
    padding-right: 40px; */
}
.secondRow{
    display: flex;
    justify-content: space-between;
    /* gap: 350px; */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
}
.mainBodyss label{
    font-size: 19px;
    font-weight: 2000;
    text-align: justify;
    /* padding-top: 18px; */
    padding-right: 5px;
    
}
.bLabel{
    text-align: center;
    font-weight: 600;
}
.aLabel{
    font-weight: 600;
}
.cLabel{
    font-size: 19px;
    font-weight: 2500;
    text-align: left;
    width: auto;
}

.styled_input {
    /* width: 100%; */
    height: 20px;
    padding: 0;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
    /* padding-left:50% ; */
}

.styled_input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.styleinputD {
    /* width: 15%;
    height: 20px;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease; */
    /* padding-left:50% ; */
}

.styled_inputD:focus {
    /* border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); */
}
