*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: #6C757D;
    /* --Background:rgba(172, 231, 199, 0.05); */
}
.formSection{
    width: 100%;
    padding-left: 20rem;
    /* background-color: #4b9483; */
    padding-right: 3rem;
}
.formSectionHeader{
  width: 100%;
  padding-top: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSection p{
    font-size: 14px;
    font-family: 'inter';
}
.formSectionHeader h3{
    color: var(--Light-green);
    font-size: 20px;
    font-weight: 700;
}
.formSectionHeader h4{
    font-weight: 700;
}
.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    border-radius: 12px;
    width: 100%;
}

.serchsection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 79px;
}
.search{
    border: 1px solid #ADB5BD;
    color: #ADB5BD;
    width: 376px;
    padding: 5px 29px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
}


.mainTabs{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.tableCont{
    width: 100%;
    border: 1px solid var(--Light-green);
}

.flakes {
    width: 47px;
    height: 47px;
}
.nestle {
    width: 47px;
    height: 47px;
}

.incrmntbtns {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.dcrmnt {
    border: 0.5px solid #2D995F;
    color: #6C757D;
    border-radius: 4px;
    padding: 2px 10px;
}
.incrmnt {
    border: 0.5px solid #2D995F;
    color: #6C757D;
    border-radius: 4px;
    padding: 2px 10px;
}

.trash {
    width: 17px;
    height: 17px;
}

.shortcurt {
    border: 1px solid #2D995F;
    width: 845px;
    margin-top: 108px;
}

.prdtxt {
    border-bottom: 1px solid #2D995F;
}

.prods {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
}

.nascotxt {
    padding-left: 15px;
    font-weight: 300;
    font-size: 10px;
    color: #343A40;
    font-family: 'inter';
}
.nestletxt {
    padding-left: 15px;
    font-weight: 300;
    font-size: 10px;
    color: #343A40;
    font-family: 'inter';
}

.headprodtxt {
    font-size: 12px;
    font-weight: 400;
    color: #343A40;
    margin-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'inter';
}

.nasco {
    display: flex;
    border: 1px solid #ADB5BD;
    align-items: center;
    padding: 7px 15px 7px 8px;
    /* border-radius: 5px; */
    margin-left: 10px;
}

.nascoflakes {
    width: 40px;
    height: 40px;
}

.nestlemiloimg {
    width: 41px;
    height: 41px;
}
.nestlemilo {
    display: flex;
    border: 1px solid #ADB5BD;
    align-items: center;
    padding: 7px 15px 7px 8px;
    /* border-radius: 5px; */
    margin-left: 10px;
    
}

.entireprod {
    display: flex;
}

.rcptinvc {
    display: flex;
    margin-top: 25px;
    gap: 20px;
}

.prnttcpt {
    font-size: 14px;
    font-weight: 400;
    color: white;
    background-color: #2D995F;
    width: 323px;
    height: 41px;
    border-radius: 4px;
    font-family: 'inter';
}

.clritms {
    border: 1px solid #2D995F;
    color: #2D995F;
    font-size: 14px;
    font-weight: 400;
    width: 323px;
    height: 41px;
    font-family: 'inter';
}