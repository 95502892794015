/* colors */
:root{
  --Deep-green: #164B2E;
  --Light-green: #2D995F; 
  --Gray:#f2f3f3;
  --White: #ffffff;
  --Black: #343A40;
  --blackGray: #6C757D;
}


.background {
    background-image: url('../../assets/promix/Login.svg');
    background-size: cover; /* Cover the entire container */
    width: 100%;
    background-position:center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    height: 100vh; /* 100% of the viewport height */
    overflow: hidden;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 7rem;

  }

  .otpGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .otpInputs {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .otpInput {
    width: 57px;
    height: 69px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #e4e4e4;
  }

  .mainContainerx {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 500px;
    height: 523px;
    border-radius: 20px;
    

    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  .thecntnt{
    display: flex;
    flex-direction: column;
    color: var(--White);
    gap: 10px;
    width: 100%;
    max-width: 90%;
  }
  .thecntnt form{
    display: flex;
    flex-direction: column;
    color: var(--White);
    gap: 20px;
    width: 100%;
   
  }

  .head{
    text-align: center;
  }
  .thecntnt p{
    color: var(--White);
  }

  .SignUptxt {
    font-family: 'inter';
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .SignUptxt1 {
    font-family: 'inter';
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 10px;
    /* margin-top: 20px; */
  }

  .SignUptxtsub {
    font-family: 'inter';
    font-weight: 400;
    font-size: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 15px;
  }
  .SignUptxtsub1 {
    font-family: 'inter';
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 15px;
  }

  .snupbtn {
    /* background-color: #2D995F; */
    color: white;
    width: 100%;
    margin-top: 32px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    padding: 12px;
  }

  .lgin {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }

  .lgin span {
    color: #2D995F;
  }/* colors */
:root{
  --Deep-green: #164B2E;
  --Light-green: #2D995F; 
  --Gray:#f2f3f3;
  --White: #ffffff;
  --Black: #343A40;
  --blackGray: #6C757D;
}


.background {
    background-image: url('../../assets/promix/Loginback.jpg');
    background-size: cover; /* Cover the entire container */
    width: 100%;
    background-position:center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    height: 100vh; /* 100% of the viewport height */
    overflow: hidden;
    display: flex;
    /* justify-content: right; */
    /* align-items: center; */
    padding-right: 4rem;

  }

  .leftcont {
    margin-left: 60px;
    width: 1600px;
  }

  .chartcontain {
    background-color: white;
    height: 240px;
    width: 480px;
    display: flex;
    margin-top: 55px;
    /* justify-content:; */
  }

  .chartcontainrt {
    /* background-color: white; */
    width: auto;
    height: 210px;
    width: 197px;
    /* display: flex; */
    /* justify-content:; */
  }
  .chartcontainmd {
    /* background-color: white; */
    width: auto;
    height: 210px;
    width: 197px;
    margin-left: -20px;
    margin-top: -65px;
    /* display: flex; */
    /* justify-content:; */
  }
 
  .chartcontainlt {
    /* background-color: white; */
    width: auto;
    height: 210px;
    width: 197px;
    margin-left: -20px;
    /* display: flex; */
    /* justify-content:; */
  }

  .otpGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .otpInputs {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .otpInput {
    width: 57px;
    height: 69px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #e4e4e4;
  }

  .mainContainerx {
    /* background-color: rgba(0, 0, 0, 0.8); */
    width: 100%;
    max-width: 500px;
    height: 523px;
    border-radius: 20px;
    display: flex;
    /* justify-content: rig   ht; */
    align-items: center;
    margin-top: 40px;
  
  }

  .logggg {
    height: 100px;
    width: 180px;
    margin-left: 20px;
    /* margin-top: 7px; */

  }

  .chartssss {
    height: 180px;
    width: 170px;
    /* margin-left: 10px; */
    /* margin-top: 7px; */

  }

  .chartssss1 {
    height: 180px;
    width: 170px;
    /* margin-left: 10px; */
    margin-top: -40px;

  }

  .endcont {
    font-family: inter;
    font-weight: 800;
    font-size: 35px;
    color: white;
    background-color: #ffffff00;
  }


  .thecntnt{
    display: flex;
    flex-direction: column;
    color: var(--White);
    gap: 10px;
    width: 100%;
    max-width: 90%;
  }
  .thecntnt form{
    display: flex;
    flex-direction: column;
    color: var(--White);
    gap: 20px;
    width: 100%;
   
  }

  .head{
    text-align: center;
  }
  .thecntnt p{
    color: var(--White);
  }

  .SignUptxt {
    font-family: 'inter';
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .SignUptxt1 {
    font-family: 'inter';
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 10px;
    /* margin-top: 20px; */
  }

  .SignUptxtsub {
    font-family: 'inter';
    font-weight: 400;
    font-size: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 15px;
  }

  .SignUptxtsub1211 {
    background-color: rgba(255, 0, 0, 0) !important;
    color: white;
    width: 500px;
  }

  .SignUptxtsub1 {
    font-family: 'inter';
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 15px;
    width: 500px;
  }

  .snupbtn {
    /* background-color: #2D995F; */
    color: black;
    width: 100%;
    margin-top: 32px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'inter';
    margin-left: 50px;
    padding: 12px;
    background-color: white !important;
    width: 400px;
  }

  .lgin {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }

  .lgin span {
    color: #2D995F;
  }

  @media (max-width: 800px) {
    .background {
      background-image: url('../../assets/promix/Loginback.jpg');
      background-size: cover; /* Cover the entire container */
      width: 100%;
      background-position:center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      height: 100vh; /* 100% of the viewport height */
      /* overflow: hidden; */
      display: block;
      /* justify-content: right; */
      /* align-items: center; */
      padding-right: 4rem;
    } 
    .chartcontain, .chartcontainrt, .chartssss, .chartssss1{
      display: none;
      display: hidden;
    }

    .endcont {
      display: none;
      display: hidden;
    }
    .mainContainerx {
      width: 100%;
      border-radius: 20px;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
    }

    .thecntnt{
      display: flex;
      flex-direction: column;
      color: var(--White);
      /* gap: 10px; */
      width: 100%;
    }

    .SignUptxtsub1211 {
      background-color: rgba(255, 0, 0, 0) !important;
      color: white;
      width: 300px;
    }

    .formGroup {
      margin-left: 20px;
    }
    .snupbtn {
      /* background-color: #2D995F; */
      color: black;
      margin-top: 32px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'inter';
      /* margin-left: 50px; */
      padding: 12px;
      background-color: white !important;
      width: 230px;
    }

    .head{
      text-align: center;
      margin-left: 60px;
    }

  }