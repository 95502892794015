body {
    background-color: rgba(172, 231, 199, 0.05) !important;

}
*,
::after,
::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --Black: #343A40;
    --Background:rgba(172, 231, 199, 0.05);
}

.horinfodashbrd {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    /* height: 107px; */
    padding: 20px 10px 10px 20px;
    background-color: #ffffff;
    /* border: 1px solid #CED4DA; */
    }
    
    .usrcnr {
      display: flex;
      align-items: center;
    
      
    }
    
    
    
    .avtr {
      width: 62px;
      height: 62px;
      border-radius: 50px;
      /* margin-top: -5px; */
      background-color: #2D995F;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .avtr1 {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      /* margin-top: -5px; */
      background-color: #2D995F;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .avtrs {
      width: 62px;
      height: 62px;
      border-radius: 50px;
      /* margin-top: -5px; */
      background-color: #2D995F;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .bel {
      width: 24px;
      height: 24px;
      margin-bottom: 10px;
      /* padding-right: 8px; */
      margin-left: -3%;
    }
    
    .usrinfo {
      display: flex;
    justify-content: flex-end;
    }
    
    .usrnminf {
    margin-right: 25px;
    text-align: right;
    }

.formSection{
    width: 100%;
    padding-left: 20rem;
    /* background-color: #4b9483; */
    padding-right: 3rem;
}
.formSectionHeader{
  width: 100%;
  padding-top: 20px;
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.dashBox{
    width: 310px;
    height: 300px;
    background-color: #ffffff;
    border: 1px solid #2D995F;
    border-radius: 8px;
    padding: 10px;
}
.receivableBoard{
    margin-top: 30px;
    width: 100%;
    height: 150px;
    background-color: #ffffff;
    border: 1px solid #2D995F;
    border-radius: 8px;
    padding: 10px;
}
.progress p{
font-size: 12px;
color: #6C757D;
margin-top: 10px;
}
.progress h1{
font-size: 18px;
color: #6C757D;
font-weight: 700;

}
.progress1 p{
font-size: 12px;
color: #6C757D;
margin-top: 15px;
}
.progress1 h1{
font-size: 18px;
color: #6C757D;
font-weight: 700;

}
.pieContainer{
width: 100%;
display: flex;
flex-direction: row;
/* justify-content: space-between; */
}
.detCont{
    display: flex;
flex-direction: row;
justify-content: center;
/* align-items: center; */
gap: 5px
}
.detailsssss{
    display: flex;
flex-direction: column;
}
.acctMoney{
    margin-top: -5px;
    font-size: 12px;
    color: #6C757D;
}
.acctMoney1{
    font-size: 10px;
    color: #6C757D;
}
.dashPie{
    display: flex;
    flex-direction: column;
    gap: 10px
}
.smallBox1{
    width: 7px;
    height: 7px;
    background-color: #164B2E;
}
.smallBox2{
    width: 7px;
    height: 7px;
    background-color: #227247;
}
.smallBox3{
    width: 7px;
    height: 7px;
    background-color: #2D995F;
}
.smallBox4{
    width: 7px;
    height: 7px;
    background-color: #5DCF92;
}
.smallBox5{
    width: 7px;
    height: 7px;
    background-color: #ACE7C7;
}
.cashFlow{
    margin-bottom: 20px;
}
.cashFlow p{
    color: #343A40;
    font-size: 14px;
}
.cashFlow h1{
    color: #2D995F;
    font-size: 25px;
    font-weight: 700;
}

.cashFlow h6{
    color: #343A40;
    font-size: 12px; 
}

.formSection p{
    font-size: 14px;
}
.formSectionHeader h3{
    color: var(--Light-green);
    font-size: 20px;
    font-weight: 700;
}
.formSectionHeader h4{
    font-weight: 700;
}
.formSecCont{
    padding: 24px 32px;
    background-color: var(--White);
    border-radius: 12px;
    width: 100%;
}
.analysis{
    padding: 18px 100px 14px 32px;
    background-color: var(--Deep-green);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    /* color: var(--White); */
}
.analysisCont h5,
.analysisCont p{
    color: var(--White);
    margin: 0;
}
.perceCont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    padding-top: 10px;
    /* color: var(--White); */
}
.percent{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    /* color: var(--White); */
}

.dashboardName{
    width: 60%;
}
.userSide{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: -1%;
   /* margin-left: 20%; */
    /* width: 40%; */

}
.userSide h3{
    -webkit-line-clamp: 1;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: right;
}
.userSide p{
    margin-top: -5%;
}
.dashboardName h4{
    -webkit-line-clamp: 1;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

}



.chartCont{
    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 20px;
}
.chartBox{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin-left: 10px;

    /* max-width: 420px; */
    /* height: 395px; */
    overflow: hidden;
}
.chartBoxx{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin-left: 10px;

    /* max-width: 420px; */
    /* height: 395px; */
    /* overflow: hidden; */
}
.imgs{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.twoCole{
    grid-column: 1/3;
    /* grid-row: ; */
    
}

@media (max-width: 900px) {
    .formSection{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }    
    .chartCont{
        display: flex;
        /* grid-template-columns: repeat(3, 1fr); */
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        /* margin-top: 20px; */
    }
}

@media (max-width: 992px) {
    .formSection {
        padding-top: 110px;
    }

    .userSide{
        margin-right: 2%;
        text-align: right;
    
    }
    .userSide h3{
        margin-right: -2px;
    }

    .chartBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 45px;
    }
    .chartBoxx {
        width: 82%;
        flex-direction: column;
        margin-left: 45px;
    }
    .chartBoxx1 {
        width: 75%;
        flex-direction: column;
        margin-left: 45px;
    }
}
