/* .newDashbody{
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    padding-top: 20px;
}
.newDashCont{
    width: 90%;
 
}
.navSectionHead{
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navSectionHead h4{
    font-weight: 700;
    font-size: 20px;
    margin-top: 2px;
} */

.navSection {
  width: 100% !important;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 10px 0 10px 105px;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #3770ab;
  /* border-top: 2px solid black; */
  border-top: 2px solid rgba(172, 231, 199, 0.05);
  z-index: 10;
}
.navSectionNavCont {
  width: 100%;
  max-width: 85%;
  display: flex;
  justify-content: flex-start;
}

.navSectionNavCont.active {
  display: block;
}

.navSectionNav {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 0.2em;
  align-items: center;
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  width: 100%;
  max-width: 70%;
}
.Dropdowns {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  /* padding: 15px; */
  width: 100%;
  min-width: 120px;
  z-index: 11; /* Ensure dropdown buttons have a higher z-index */
  position: relative;

  /* background-color: gray; */
}
.DropdownMenu {
  z-index: 12 !important; /* Ensure dropdown menu has the highest z-index */
  position: absolute;
  background-color: white;
}
.DropdownMenu a {
  font-size: 14px !important;
}
/* .dropFonts{
    font-size: 14px !important;
} */
.Dropdowns:hover {
  background-color: transparent !important;
}
.Dropdowns .active {
  /* background-color: green;
    color: var(--White); */
}
.Dropdowns span {
  font-size: 16px;
  font-weight: 400;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* gap: 5px; */
  width: 100%;
  /* min-width: 150px; */
}
.Dropdowns span img {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

/* Target the default dropdown indicator */
.dropdown-toggle::after {
  /* Add your styles here */

  all: unset !important;
  display: none !important; /* Hide the default arrow if you want to replace it */
}
.divider {
  background-color: rgb(228, 226, 226) !important;
  height: 2px; /* Optionally adjust the height */
}

/* .Dropdowns .dropdown-toggle {
    position: relative; 
    padding-right: 25px;
} */

/* .Dropdowns .dropdown-toggle::before {
    content: ''; 
    position: absolute;
    right: 10px; 
    top: 50%;
    transform: translateY(-50%);
    width: 10px; 
    height: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #000 transparent transparent transparent; 
} */

.Dropdowns:focus,
.Dropdowns.show,
.Dropdowns:active {
  background-color: unset !important;
  color: #fff !important;
  box-shadow: none !important;
  outline: none !important;
}

/* Optional: Ensure the dropdown items don't show active state */
/* .dropdown-item:focus, */
/* .dropdown-item:hover */

.hamburger {
  display: none;
}

/* Mobile Styles */
/* Mobile Styles */
@media (max-width: 992px) {
  .navSectionNav {
    display: none; /* Hide by default */
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 59;
  }

  .navSectionNav {
    width: 100%;
    margin: 0;
    background-color: white;
    right: 45%;
    padding: 20px;
    top: 0.5%;
  }

  .navSectionNav.show {
    display: flex; /* Show the menu when the hamburger is clicked */
    flex-direction: column;
    display: block;
    width: 100%;
    height: auto; /* Adjust height based on content */
  }

  .navSectionNavCont.active .navSectionNav {
    display: flex;
    display: none;
  }

  .dropdown-item:active {
    background-color: #28166F !important;
    /* color: inherit; Optional: keep the text color the same */
  }
  .hamburger {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: left;
    cursor: pointer;
    display: block;
  }

  .Dropdowns {
    background-color: white !important;
    border: none !important;
    color: #fff !important;
    /* padding: 15px; */
    width: 100%;
    min-width: 120px;
    z-index: 1000; /* Ensure dropdown buttons have a higher z-index */
    position: relative;

    /* background-color: gray; */
  }

  .navSection {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 90px;
    position: fixed;
    left: 25.8%;
    top: 1%;
    background-color: transparent !important;
    /* border-top: 2px solid black; */
    border-top: 2px solid rgba(172, 231, 199, 0.05);
    z-index: 999;
  }

  .DropdownMenu {
    z-index: 1025 !important; /* Ensure dropdown menu has the highest z-index */
    position: absolute;
    background-color: white;
  }
  .DropdownMenu a {
    font-size: 14px !important;
  }
}
