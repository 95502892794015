*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* colors */
:root {
  --Deep-green: #164b2e;
  --Light-green: #2d995f;
  --Gray: #f2f3f3;
  --White: #ffffff;
  --Black: #343a40;
}
.fetchedTxt {
  font-size: 16px;
  font-weight: 500;
}

.productText {
  font-weight: 700;
  font-size: 30px;
  /* margin-left: 15rem; */
}

.newmobile {
  margin-left: -125px;
}

.newmobile12 {
  background-color: #2d995f;
  color: #ffffff;
  border: 1px solid #e7e7e7 !important;
  margin-top: 2.3rem;
  margin-left: -80px;
  width: fit-content;
  height: fit-content; 
}

.selectGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three items per row */
  gap: 10px; /* Optional: Adjust spacing between items */
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 10px;
}


.finishedbodyCont {
  width: 100%;
  padding: 0 7rem 0 7rem;
  padding-top: 5rem;
  /* padding-right: 4rem; */
}
.finishedbodyCont h3 {
  font-size: 30px;
  font-weight: 700;
  margin: 10px 10px 0 0;
}
.assignBtn {
  background-color: #fff;
  border-color: rgb(11, 11, 51);
  border-width: 1px;
  height: 30px;
  width: 100px;
  color: rgb(11, 11, 51);
  font-size: 12px;
  font-weight: 700;
}

.selectGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 10px;
  max-height: 150px;
  overflow-y: auto;
}

.assignBtn1 {
  background-color: #a31717;
  /* border-color: rgb(11, 11, 51); */
  /* border-width: 1px; */
  height: 30px;
  width: 100px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}
.finishedbodyCont p {
  margin: 0 10px 20px 0;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  /* user-select: none; */
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 3px;
}

.checkboxContainer:hover .checkmark {
  background-color: #f0f0f0;
}

.checkboxContainer input:checked ~ .checkmark {
  background-color: #4caf50;
  border-color: #4caf50;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

.checkboxContainer .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.label {
  color: #333;
}

.finishedbodyCont p {
  margin: 0 10px 20px 0;
}
.mainTable table td {
  padding: 0 0 0 5px;
  /* padding: 15px 0 0 5px; */
  align-items: center;

  /* display: none; */
}

.endded {
  /* display: flex; */
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
}
.topPadding {
  width: 100%;
  padding-top: 1rem;
}
.formSecCont {
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formSecCont h3 {
  color: #343a40;
  font-size: 20px;
  font-weight: 700;
}

.shadow {
  box-shadow: 0 0 5px rgb(238, 236, 236);
}

.applycntnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.loandethead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 30px; */
}
.loandethead .formIntBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  gap: 10px;
}

.formIntBtn .btn1 {
  background-color: var(--White);
  border: 1px solid rgb(187, 183, 183) !important;
  color: var(--Black);
  font-size: 14px;
  font-weight: 600;
}
.formIntBtn .btn1:hover {
  border: 2px solid var(--Light-green) !important;
  color: var(--Light-green);
  background-color: transparent;
}
.formIntBtn .btn2 {
  font-size: 14px;
}
.formIntBtn .btn2 {
  /* font-size: 14px; */
  background-color: var(--White);
  border: 1px solid rgb(187, 183, 183) !important;
  color: var(--Black);
  font-size: 14px;
  font-weight: 600;
}
.formIntBtn .btn2:hover {
  border: 2px solid var(--Light-green) !important;
  color: var(--Light-green);
  background-color: transparent;
}

/* .loandethead button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: var(--Light-green);
  font-size: 16px;
  gap: 5px;
} */
.loandethead button:hover {
  background-color: var(--Light-green);
  color: var(--White);
}

.loanText {
  font-size: 15px;
}
.loanText1 {
  font-size: 15px;
  margin-left: 20px;
}
.loanText2 {
  font-size: 15px;
  margin-left: 25px;
}

.fetchText {
  font-size: 15px;
  color: rgba(45, 153, 95, 1);
  font-weight: 500;
}

.theamount {
  font-size: 20px;
  font-weight: 700;
  color: rgba(45, 153, 95, 1);
  margin-left: 30px;
}

.monthText {
  font-size: 18px;
  font-weight: 700;
  color: rgba(108, 117, 125, 1);
  margin-left: 65px;
}

.loandgrantcards {
  margin-bottom: 30px;
  margin-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}

.loanContainer {
  margin-top: 15px;
  overflow-x: auto;
}

.loanTable {
  color: black;
  background-color: rgba(233, 236, 239, 1);
  height: 50px;
  font-weight: normal;
  white-space: nowrap;
  /* padding: 30px; */
  text-align: center;
}
/* .loanResponsive {
overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
} */

/* .tableText{
font-size: 15px;
font-weight: normal;

} */
.loanResponsive table th {
  background-color: var(--Light-green);
  color: white;
  /* font-size: 14px; */
}
.loanResponsive table td {
  font-size: 14px;
  border: none !important;
}
.loanResponsive table > td,
th {
  /* border: none; */
  /* font-size: 12px; */
}
.loanResponsive .edit {
  color: var(--Light-green);
  cursor: pointer;
  text-align: center;
}
.loanResponsive .accrodBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--Light-green);
}
.accrodBtns p {
  margin: 0;
  font-size: 18px;
}
.loanResponsive .accrodBtns .prev {
  border: 1px solid #2d995f;
  color: var(--Light-green);
  background-color: transparent;
  padding: 5px 20px;
}
.loanResponsive .accrodBtns .next {
  padding: 5px 20px;
}
/* .loanResponsive .edit:hover{
  border: 1px solid var(--Light-green);
  background-color: var(--Light-green);
  color: var(--White);
  border: 10px;
} */

.imgapply img {
  width: 170px;
  height: 165px;
}

.applygrnttxt {
  font-family: "inter";
  color: #2d995f;
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grntapplytxt {
  color: rgba(108, 117, 125, 1);
  font-weight: 400;
  font-size: 16px;
}

.applyLoan {
  width: 300px;
  height: 55px;
  background-color: #2d995f;
  color: white;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.applyLoan:hover {
  background-color: #46a874;
}

.continueReg {
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.regContainer {
  display: grid;
  grid-template-columns: 25% 75%;
  /* gap: 30px; */
}
.sideNav {
  background-color: var(--Deep-green);
  padding: 37px 0 0 21px;
  height: 1800px;
  max-width: 339px;
}
.regMenu {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.regMenu a {
  text-decoration: none;
}
/* .regMenu a:active{
    background: linear-gradientrgba((#2D997F) 100%, (#2D997F) 31%);
} */
.regMenu p {
  color: var(--White);
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
.regMenu .active {
  background: linear-gradient9(#2d997f) (#2d997f) 0;
}
.formSection {
  padding: 35px 30px;
}
.formSection p {
  font-size: 14px;
}
.formSection h1 {
  color: var(--Light-green);
  font-size: 28px;
  font-weight: 700;
}
.formSecCont {
  padding: 24px 32px;
  background-color: var(--White);
  /* border-radius: 12px; */
}
.formSecCont h3 {
  color: #343a40;
  font-size: 20px;
  font-weight: 700;
}
.mainform {
  width: 100%;
  background-color: var(--White);
  /* border-radius: 12px; */
  margin-top: 26px;
  display: flex;
  flex-direction: column;
}
.actionsBtns button {
  border-radius: 0;
  font-size: 14px;
}
.diffbtn {
  width: 145px !important;
  height: 35px;
}
.btnStyle {
  padding: 20px 0 0 0;
  margin-bottom: 20px;
}
.greenbtn {
  display: flex;
  gap: 20px;
  /* margin-top: 15px; */
  /* margin-left: 2rem; */
}
.greenbtn button {
  /* display: flex; */
  margin-top: 4px;
  /* margin-left: 2rem; */
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  background-color: #198754;
  border-color: #198754;
  height: 35px;
  /* border-radius: 2px; */
  box-shadow: 0 2px 6px 0 rgba(40, 167, 69, 0.5);
  cursor: pointer;
  border-radius: 0;
}
.btndivStyle {
  display: flex;
  gap: 20px;
}

@media (max-width: 900px) {
  .finishedbodyCont {
    padding-left: 0;
    padding-right: 0;
  }
  .formIntBtn .btn2 {
    /* font-size: 14px; */
  
   width: 100px;
  }
  .finishedbodyCont h3 {
    text-align: left;
    font-size: 20px;
  }
  .finishedbodyCont p {
    text-align: left;
    font-size: 12px;
    width: 140px;
    white-space: wrap;
  }
  .btnStyle {
    display: flex;
    flex-direction: column;
  }
  .btndivStyle {
    display: inline-block;
    gap: 0px;
  }
  .show {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .formSecCont {
    padding: 21px 25px;
    background-color: var(--White);
    border-radius: 0;
    margin-bottom: 20px;
  }
  .formSecCont h3 {
    color: #343a40;
    font-size: 20px;
    font-weight: lighter;
  }
  .actionsBtns {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
  }
  .diffbtn {
    width: 145px !important;
    height: 35px;
  }
  .applygrnttxt {
    font-size: 18px;
    margin-top: 17px;
    margin-bottom: 4px;
  }

  .grntapplytxt {
    padding: 0 34px;
    font-size: 14px;
  }
  .applyLoan {
    width: 236px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }

  .continueReg {
    /* color: white; */
    /* font-size: 16px; */
    font-weight: 400;
    /* text-align: center; */
  }

    .finishedbodyCont{
    width: 100%;
    /* padding: 1rem 3rem 0 3rem; */
  }

  .endded {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between; */
  margin-top: 25px;
  align-items: center;
}

.mainTable table td {
  padding: 0 0 0 5px;
  /* padding: 15px 0 0 5px; */
  align-items: center;
  overflow-x: scroll;
  /* display: none; */
}

.newmobile {
  margin-left: 0;
}

.newmobile12 {
  background-color: #2d995f;
  color: #ffffff;
  border: 1px solid #e7e7e7 !important;
  margin-top: 35;
  margin-left: 10px;
  width: fit-content;
  height: fit-content; 
}

  
}
