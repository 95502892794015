@page {
  size: A5 landscape;
  margin: 10mm;
}

.receiptcontainerbody {
  font-family: Arial, sans-serif;
  padding: 45px;
  /* overflow-y: hidden; */
}

.receiptcontainer {
  /* width: auto; */
  background-repeat: no-repeat;
  padding: 10mm;
  box-sizing: border-box;
  /* border: 1px solid #000; */
  /* background-image: url('../Login/bckrccg.png'); */
  background-size: cover;
  /* background-size: auto; */
}

.receiptcontainer123 {
  width: 100%;
  padding: 1.5mm;
  box-sizing: border-box;
  border: 3px solid #000;
  /* display: flex; */
  /* justify-content: space-between; */
}

.rDGDeceiptcontainer123 {
  width: 100%;
  padding: 1.5mm;
  box-sizing: border-box;
  border: 3px solid #000;
  /* display: flex; */
  /* justify-content: space-between; */
}

.receiptcontainer14 {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.sectiondatesection td {
  border: 1px solid #000;
  text-align: center;
  padding: 5px;
}

.sectiondatesection th {
  /* border: 1px solid #000; */
  text-align: left;
  padding: 5px;
}

.receiptcontainer444 {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: 73px;
}

.receiptcontainer44234 {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: 55px;
}

.receiptcontainer1223234 {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: 13px;
}

.redadfptcontainer444 {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: 68px;
}

.receiptcontainer1234 {
  width: 100%;
  padding: 4mm;
  box-sizing: border-box;
  /* border: 1px solid #000; */
  /* display: flex; */
  /* justify-content: space-between; */
}

.title {
  text-align: left;
  font-size: 45px;
  color: var(--green);
  font-weight: bold;
  /* margin-bottom: 10px; */
}

.header {
  display: flex;
  justify-content: space-around;
  /* margin-bottom: 15px; */
  gap: 10px;
}

.header .date, .header .number {
  /* width: 48%; */
  margin-top: 40px;
}

.section {
  margin-bottom: 10px;
}

.label {
  display: inline-block;
  width: 100%;
  margin-left: 85px;
}

.label12 {
  display: inline-block;
  width: 100%;
  /* margin-left: 2px; */
  font-size: 18px;
  color: black;
}

.label1 {
  display: inline-block;
  width: 100%;
  margin-left: 12px;
}

.label13412 {
  font-size: 17px;
  color: black;
  /* margin-left: 20px; */
}

.inputline {
  /* border: 1px solid #000; */
  display: inline-block;
  /* width: 200px; */
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 20px;
  text-align: right;
  padding: 5px;
  padding-right: 7px;
}

.inputline2 {
  border-bottom: 1px solid #000;
  display: inline-block;
  width: 60%;
  padding: 5px;
  font-size: 15px;
}
.inputliner76 {
  border: 1px solid #000;
  padding: 5px;
}

.inputliner716 th {
  border: none;
  padding: 5px;
  text-align: right;
  background-color: none;
}

.inputliner716 td {
  border: 1px solid #000;
  padding: 5px;
  background-color: none;
  text-align: left;
}

.inputliner716 {
  border: none;
  padding: 5px;
  text-align: right;
  width: 70%;
}

.inputline3 {
  border-bottom: 1px solid #000;
  display: inline-block;
  /* width: 90%; */
  padding: 5px;
}

.inputline31 {
  border: 1px solid #000;
  display: inline-block;
  padding: 5px;
}

.inputline313 {
  border: 1px solid #000;
  display: inline-block;
  padding: 5px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 20px;
  /* background-color: var(--green); */
}

.sectiondatesection {
  margin-left: 25px;
}

.sectiondatesection tr, th, td {
  margin-top: 5px;
  background-color: rgba(255, 255, 255, 0);

}

.sectiondatesection12 {
  margin-left: 28px;
  margin-top: 5px;
}

.amountlabel, .amountvalue {
  display: inline-block;
  vertical-align: middle;
}

.sectionamount {
  /* margin-left: 50px; */
}

.amountlabel {
  /* min-width: 150px; */
  color: black;
  /* margin-left: 20px; */

}

.amountvalue {
  font-size: 24px;
  font-weight: bold;
}

.fullline {
  width: calc(100% - 160px);
}

.datesection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.contactsection {
  margin-bottom: 10px;
}

.contactsection div {
  margin-bottom: 5px;
}

.paymentsection {
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 1px solid #000;
  padding-top: 10px;
}

.footeritem {
  width: 30%;
  text-align: center;
}

@media print {
  @page {
      size: A4 portrait;
      /* margin: 10mm; */
    }
    .inputline {
      /* border: 1px solid #000; */
      display: inline-block;
      /* width: 200px; */
      color: rgb(0, 0, 0);
      font-size: 15px;
      font-weight: 20px;
      text-align: right;
      padding: 5px;
      /* background-color: var(--green); */
    }

    .inputline2 {
      border-bottom: 1px solid #000;
      display: inline-block;
      width: 65%;
      padding: 5px;
      font-size: 12px;
    }

    .receiptcontainer {
      /* width: auto; */
      background-repeat: no-repeat;
      padding: 5mm;
      box-sizing: border-box;
      /* border: 1px solid #000; */
      /* background-image: url('../Login/bckrccg.png'); */
      background-size: cover;
      /* background-size: auto; */
    }

    .inputliner716 th {
      border: none;
      padding: 5px;
      text-align: right;
      background-color: none;
    }
  
    .inputliner716 td {
      border: 1px solid #000;
      padding: 5px;
      background-color: none;
      text-align: left;
    }

    .inputline313 {
      /* border: 1px solid #000; */
      display: inline-block;
      padding: 5px;
      color: rgb(0, 0, 0);
      font-size: 14px;
      font-weight: 20px;
      /* background-color: var(--green); */
    }

    .receiptcontainerbody {
      font-family: Arial, sans-serif;
      padding: 0;
      margin: 0;
    }

}